import React, { useEffect, useState } from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import Progress from "../../images/progress.jpg";
import Spinner from "react-bootstrap/Spinner";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Godigit from "../../images/Godigit.png";
import * as api from "../../API/authCurd.js";
import futureGenrali from "../../images/futureGenrali.png";
import ICICI from "../../images/ICICI-insurance.png";
import reliance from "../../images/reliance.png";
import NewIndia from "../../images/new-india.png";
import UseFormContext from "../../context/UseFormContext";
import { PageNotFound } from "../commonModules/PageNotFound";
import axios from "axios";
import Bajaj from "../../images/bajaj.png";
import HDFC from "../../images/hdfc-ergo-gi.png";
import AdminService from "../../services/admin.service";
import Validations from "../../validations/validations";
import { useFormik } from "formik";
import { decryptData } from "../../services/auth-service";
import { CiShare2 } from "react-icons/ci";
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import NavDropdown from "react-bootstrap/NavDropdown";
import bike from "../../../src/images/bike.png";
import car from "../../../src/images/car1.png";
import { FaSearch, FaTimes } from "react-icons/fa";
import { DateRangePicker } from "rsuite";
import { createBikePolicyDownload } from "../../API/authCurd.js"
import { createCarPolicyDownload } from "../../API/authCurd.js"
import generatePolicyModal from "../pages/Health/PolicyGenerateModal.js"
import Care from "../../images/careLogo.jpg";
import "rsuite/dist/rsuite.css";
import "./Health/CSS/MyprofileStyle.css"

var CryptoJS = require("crypto-js");

export const MyProfileAdmin = () => {
  const [shareUrl, setshareUrl] = useState(
    process.env.REACT_APP_FRONTEND_SERVER
  );
  const [policyDownloadLink, setPolicyDownloadLink] = useState(
    process.env.REACT_APP_FRONTEND_SERVER
  );
  const title = "Check out your Policy copy on policies365.com";
  const { id } = useParams();
  const [mobile, setMobile] = useState();
  const [initialData, setInitialData] = useState(null);
  const [toggle, setToggle] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState();
  const formContext = UseFormContext();
  const validationSchema = Validations.userValidations;
  const [policyDetailsData, setPolicyDetailsData] = useState([]);
  const [policyDetailsDataOriginal, setPolicyDetailsDataOriginal] = useState(
    []
  );

  const [proposalData, setproposalData] = useState([]);
  const [proposalDataOriginal, setproposalDataOriginal] = useState([]);

  const [quoteData, setQuoteData] = useState([]);
  const [quoteDataOriginal, setQuoteDataOriginal] = useState([]);

  const [loaderStatus, setloaderStatus] = useState(false);
  const [loaderStatusQuotes, setloaderStatusQuotes] = useState(false);
  const [buyNowLoader, setbuyNowLoader] = useState();
  const [buyNowIndex, setbuyNowIndex] = useState();
  const [shareQuotesPopup, setshareQuotesPopup] = useState(false);
  const [policyCount, setPolicyCount] = useState(0);
  const [policyDowloadRepository, setPolicyDetailsRepository] = useState([]);
  const [policyDowloadRepositoryy, setPolicyDowloadRepository] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedPolicyDetails, setGeneratedPolicyDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({
    searchInput: "",
    startDate: "",
    endDate: "",
  });

  const [searchParamsForPolicy, setSearchParamsForPolicy] = useState({
    searchInput: "",
    startDate: "",
    endDate: "",
  });

  const [searchParamsForProposal, setSearchParamsForProposal] = useState({
    searchInput: "",
    startDate: "",
    endDate: "",
  });

  const [value, setValue] = useState();
  const [valuePolicy, setValuePolicy] = useState();
  const [valueProposal, setValueProposal] = useState();
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false)

  const location = useLocation();
  const history = useHistory();
  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": reliance,
    "New India Assurance": NewIndia,
    "Future Generali India Insurance Co. Ltd": futureGenrali,
    "GO DIGIT General Insurance CO. LTD": Godigit,
    "ICICI Lombard General Insurance Co. Ltd": ICICI,
    "Bajaj Allianz General Insurance Co. Ltd": Bajaj,
    "HDFC ERGO General Insurance Company": HDFC,
    "Care Health Insurance": Care
  };
  const [showOptions, setShowOptions] = useState(false);

  const closeShareQuptes = () => {
    setshareQuotesPopup(false);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get("tab");

    if (tabValue) {
      setToggle(tabValue);
      if (tabValue == 5) updateToggle(5);
      else if (tabValue == 7) updateToggle(7);
    }
  }, []);

  useEffect(() => {
    getPoliciesList();
    getPolicyDownloadRepository()
    let user1 = decryptUser();
  }, [history]);

  useEffect(() => {
    if (id) {
      document.getElementById("Prosposal").click();
    }
  }, [id]);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const decryptUser = async () => {
    setMobile(
      localStorage.getItem("ownerPhoneNumber1")
        ? localStorage.getItem("ownerPhoneNumber1")
        : localStorage.getItem("ownerPhoneNumber1")
    );
    const encryptedUser = localStorage.getItem("user");

    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);

        setUser(user1);
        fetchData(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
    // else{
    //   history.push("/signin");
    //   console.error("Error");
    // }
  };

  const fetchData = async (user) => {
    try {
      const data = {
        email: user?.email,
      };

      await AdminService.getUserByEmail(data).then((res) => {
        setInitialData(res?.data?.data);
        formik.setValues(res.data.data);
      });
    } catch (error) {
      history.push("/signin");
      console.error("Error:", error);
    }
  };

  const handleGeneratePolicy = (details) => {
    setIsLoading(true);
    if (details?.businessLineId === 2) {
      // For bike policy
      createBikePolicyDownload(details?.paymentResponse?.apPreferId, details?.paymentResponse?.proposalId, details?.paymentResponse?.Amount, details?.paymentResponse?.transactionNo)
        .then(response => {
          setGeneratedPolicyDetails({
            policyNo: response.data?.data?.policyNo,
            proposalId: response.data?.data?.proposalId,
          });
          setIsModalOpen(true);
          setPolicyDowloadRepository(prevData => prevData.filter(item => item.paymentResponse.proposalId !== details?.paymentResponse?.proposalId));
        })
        .catch(err => {
          console.error('Error generating bike policy:', err);
        }).finally(() => {
          setIsLoading(false); // Hide loader after API call completes
        });
    } else if (details?.businessLineId === 3) {
      // For car policy
      createCarPolicyDownload(details?.paymentResponse?.apPreferId, details?.paymentResponse?.proposalId, details?.paymentResponse?.Amount, details?.paymentResponse?.transactionNo)
        .then(response => {
          setGeneratedPolicyDetails({
            policyNo: response.data?.data?.policyNo,
            proposalId: response.data?.data?.proposalId,
          });
          setIsModalOpen(true);
          setPolicyDowloadRepository(prevData => prevData.filter(item => item.paymentResponse.proposalId !== details?.paymentResponse?.proposalId));
        })
        .catch(err => {
          console.error('Error generating car policy:', err);
        }).finally(() => {
          setIsLoading(false); // Hide loader after API call completes
        });
    }
  };


  const getPoliciesList = () => {
    if (initialData?.id === null || initialData?.id === undefined) {
      const userLogin = sessionStorage.getItem("userLoggin");

      if (userLogin) {
        var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        formContext.setloaderStatus(true);
        api
          .downloadPolicy(decryptedData)
          .then((data) => {
            if (data.data) {
              const jsonData = JSON.parse(data?.data);

              setPolicyDetailsData(jsonData?.data?.policyDetails.reverse());
              formContext.setloaderStatus(false);
            }
          })
          .catch((err) => {
            setPolicyDetailsData([]);
            formContext.setloaderStatus(false);
          });
      } else {
        formContext.setPages("Myprofile");
        history.push("/signin");
      }
    }
  };

  const getPolicyDownloadRepository = () => {
    const userLogin = sessionStorage.getItem("userLoggin");
    console.log("first", userLogin);

    if (userLogin) {
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log("decryptedData", decryptedData);
      formContext.setloaderStatus(true);

      api
        .policyDownloadRepository(decryptedData)
        .then((data) => {
          console.log("check data", data);
          if (data && data.data) {
            console.log("data.data", data.data);


            const jsonData = data.data;

            console.log("Print JsonData", jsonData);
            setPolicyDetailsRepository(jsonData.reverse());
            formContext.setloaderStatus(false);
          }
        })
        .catch((err) => {
          setPolicyDetailsRepository([]);
          formContext.setloaderStatus(false);
        });
    } else {
      formContext.setPages("Myprofile");
      history.push("/signin");
    }
  };

  useEffect(() => {
    getPolicyDownloadRepository(); // Fetch data on component mount
  }, []);

  const getProposal = () => {
    if (initialData?.id === null || initialData?.id === undefined) {
      setloaderStatus(true);
      const userLogin = sessionStorage.getItem("userLoggin");
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      api
        .getProposalList(decryptedData)
        .then((data) => {
          if (data.data) {
            console.log("admin data check", data?.data)
            const jsonData = JSON.parse(data?.data);
            setproposalData(
              jsonData?.data
                ? jsonData.data?.sort((a, b) => {
                  let dateA = new Date(
                    a.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  let dateB = new Date(
                    b.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  return dateB - dateA;
                })
                : []
            );
            formContext.setloaderStatus(false);
          }
        })
        .catch((err) => {
          setproposalData([]);

          // formContext.notifyError("Error while getting propsal");
        })
        .finally(() => {
          setloaderStatus(false);
        });
    }
  };

  useEffect(() => {
    console.log("Updated proposal data:", proposalData);
  }, [proposalData]);

  function carePolicyStatusService(proposalId) {
    return new Promise((resolve, reject) => {
      setloaderStatus(true);
      const userLogin = sessionStorage.getItem("userLoggin");
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      api
        .getProposalListUWAgent(decryptedData)
        .then((data) => {
          if (data.data) {
            const jsonData = JSON.parse(data?.data);
            console.log("Print proposal data", data?.data);

            const filteredData = jsonData?.data?.filter(
              (proposal) => proposal.healthProposalStatus === "PENDINGREQUIREMENTS"
            );
            console.log("Filtered data", filteredData);

            setproposalData(filteredData); 

            formContext.setloaderStatus(false);
            resolve(jsonData); 
            console.log("json data", jsonData);
          }
        })
        .catch((err) => {
          setproposalData([]); // Set empty array in case of an error
          formContext.notifyError("Error while getting proposals");
          reject(err); // Reject in case of an error
        })
        .finally(() => {
          setloaderStatus(false);
        });
    });
  }

  const getProposalDetsil = (propsalId) => {
    api
      .getProposal(propsalId)
      .then((data) => {
        if (data.data) {
          const jsonData = JSON.parse(data?.data);

          formContext.setsingleQuotesData(
            jsonData?.data?.proposalRequest?.premiumDetails
          );
          formContext.setproposalData(jsonData?.data);
          history.push("/proposal/2");
        }
      })
      .catch((err) => {
        // setproposalData([]);

        formContext.notifyError("Facing some error ,please try again.");
      })
      .finally(() => setbuyNowLoader(false));
  };

  //old
  useEffect(() => {
    if (initialData?.id) {
      //----------------------------------------------
      let dataReq = {
        agentID: initialData?.id,
        operationType: "quote",
      };
      setloaderStatus(true);
      api
        .listsForAgent(dataReq)
        .then((res) => {
          const sorted = res.data.sort(
            (a, b) =>
              new Date(b.quoteCreatedDate) - new Date(a.quoteCreatedDate)
          );
          setQuoteData(sorted);
          setQuoteDataOriginal(sorted);
        })
        .catch((err) => {
          setQuoteData([]);
          setQuoteDataOriginal([]);

          // formContext.notifyError("Error while getting propsal");
        })
        .finally(() => {
          setloaderStatus(false);
        });
      //------------------------------------------

      let dataReqProp = {
        agentID: initialData?.id,
        operationType: "proposal",
      };
      api
        .listsForAgent(dataReqProp)
        .then((data) => {
          if (data.data) {
            const jsonData = data?.data;
            console.log("json data", jsonData)
            const filteredData = jsonData
              ? jsonData
                .filter((item) => {
                  let proposalDate = new Date(
                    item.PolicyTransaction.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  let currentDate = new Date();
                  let timeDiff = currentDate - proposalDate;
                  let dayDiff = timeDiff / (1000 * 3600 * 24);
                  return dayDiff <= 15;
                })
                .sort((a, b) => {
                  let dateA = new Date(
                    a.PolicyTransaction.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  let dateB = new Date(
                    b.PolicyTransaction.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  return dateB - dateA;
                })
              : [];

            setproposalData(filteredData);
            setproposalDataOriginal(filteredData);
            formContext.setloaderStatus(false);
          }
        })
        .catch((err) => {
          setproposalData([]);
          setproposalDataOriginal([]);

          // formContext.notifyError("Error while getting propsal");
        })
        .finally(() => {
          setloaderStatus(false);
        });

      let dataReqPol = {
        agentID: initialData?.id,
        operationType: "policy",
      };
      api
        .listsForAgent(dataReqPol)
        .then((res) => {
          let allPolicyDetails;
          if (res?.data) {
            allPolicyDetails = res.data.reduce((acc, user) => {
              return acc.concat(user.policyDetails);
            }, []);
          }

          // Helper function to convert "dd/mm/yyyy" to a Date object
          const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("/");
            return new Date(`${year}-${month}-${day}`);
          };

          // Sort the data based on policyIssueDate
          const sorted = allPolicyDetails.sort(
            (a, b) =>
              parseDate(b.policyIssueDate) - parseDate(a.policyIssueDate)
          );


          const uniquePolicies = sorted.filter((policy, index, self) =>
            index === self.findIndex((p) => p.policyNo === policy.policyNo)
          );
          setPolicyDetailsData(uniquePolicies);
          setPolicyDetailsDataOriginal(uniquePolicies);
          setPolicyCount(uniquePolicies.length);
        })
        .catch((err) => {
          setproposalData([]);
          setPolicyDetailsDataOriginal([]);

          // formContext.notifyError("Error while getting propsal");
        })
        .finally(() => {
          setloaderStatus(false);
        });
      //}
    }
  }, [toggle]);



  function updateToggle(id) {
    setToggle(id);
    if (id === 6) {
      sessionStorage.clear();
      localStorage.clear();
      history.push("/");
    }
    else if (id === 8) {
      getPolicyDownloadRepository()
    } else if (id === 5) {
      getPoliciesList();
    } else if (id === 4) {
      getProposal();
    }
    else if (id === 9) {
      // getUWProposal();
      carePolicyStatusService()
    }
  }

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "document";
    link.target = "_blank";
    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 500);
  };

  const sharePolicyDownloadLink = (url) => {
    setPolicyDownloadLink(url);
  };

  const downloadFileFromAPI = (carrierId, mobile, pKey) => {
    const apiUrl =
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POLICY_URL;

    // Define request data
    const requestData = {
      header: {
        transactionName: "policyDocDownloadService",
        messageId: "",
        deviceId: "ABCD12345",
      },
      body: {
        mobile: mobile,
        pKey: pKey,
      },
    };

    // Make POST request to the API
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // Extract URL from response data
        const responseData = JSON.parse(response.data);
        const downloadLink = responseData.data.DownloadLink
          ? responseData.data.DownloadLink
          : responseData?.data?.policyDetails[0].downloadUrl;

        if (carrierId === 29) {
          window.open(downloadLink);
        } else {
          // Trigger file download using the obtained URL
          downloadFile(downloadLink);
        }
      })
      .catch((error) => {
        console.error("Error downloading file from API:", error);
      });
  };

  const [activeRow, setActiveRow] = useState(null);

  const createLinkForSharePolicy = (carrierId, mobile, pKey) => {
    setActiveRow(true);
    //setShowOptions(!showOptions);
    const apiUrl =
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POLICY_URL;

    // Define request data
    const requestData = {
      header: {
        transactionName: "policyDocDownloadService",
        messageId: "",
        deviceId: "ABCD12345",
      },
      body: {
        mobile: mobile,
        pKey: pKey,
      },
    };

    // Make POST request to the API
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // Extract URL from response data
        const responseData = JSON.parse(response.data);
        const downloadLink = responseData.data.DownloadLink;
        sharePolicyDownloadLink(downloadLink);
      })
      .catch((error) => {
        console.error("Error downloading file from API:", error);
      });
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = values;
    },
  });

  useEffect(() => {
    if (!formContext.loginType) {
      formContext.setloginType(sessionStorage.getItem("isAdmin"));
      // history.push("/myaccount")
      formContext.setPages("Myprofile");
    }
  }, []);

  const filterFunction = () => {
    // Reset to original data before applying filters
    let filteredQuotes = [...quoteDataOriginal];

    let formattedDates;
    if (value) {
      formattedDates = value?.map(formatDate); // Date range selected by user
    }

    filteredQuotes = filteredQuotes.filter((details) => {
      const { searchInput } = searchParams;
      const customerFullName =
        `${details.firstName} ${details.lastName}`.toLowerCase();

      const isSearchMatch = searchInput
        ? details?.phoneNumber?.includes(searchInput) ||
        customerFullName?.includes(searchInput?.toLowerCase())
        : true;

      let isDateValid = true;
      if (formattedDates) {
        isDateValid = isDateInRange(details.quoteCreatedDate, formattedDates); // Apply date range filtering
      }

      return isSearchMatch && isDateValid; // Both conditions must match
    });

    setQuoteData(filteredQuotes); // Set the filtered data
  };

  const filterFunctionPropsal = () => {
    // Always reset to original data before applying filters
    let filteredProposal = [...proposalDataOriginal];

    let formattedDates;
    if (valueProposal) {
      formattedDates = valueProposal?.map(formatDate); // Date range selected by user
    }

    filteredProposal = filteredProposal.filter((details) => {
      const { searchInput } = searchParamsForProposal;
      const customerFullName =
        `${details.firstName} ${details.lastName}`.toLowerCase();

      const isSearchMatch = searchInput
        ? details?.phoneNumber?.includes(searchInput) ||
        customerFullName?.includes(searchInput?.toLowerCase())
        : true;

      let isDateValid = true;
      if (formattedDates) {
        isDateValid = isDateInRange(details, formattedDates); // Apply date range filtering
      }

      return isSearchMatch && isDateValid; // Both conditions must match
    });

    setproposalData(filteredProposal); // Set the filtered data
  };

  const filterFunctionPolicy = () => {
    // Always reset to original data before applying filters
    let filteredQuotes = [...policyDetailsDataOriginal];

    let formattedDates;
    if (valuePolicy) {
      formattedDates = valuePolicy?.map(formatDate); // Date range selected by user
    }

    filteredQuotes = filteredQuotes.filter((details) => {
      const { searchInput } = searchParamsForPolicy;
      const customerFullName = `${details.Name}`.toLowerCase();

      const isSearchMatch = searchInput
        ? details?.mobile?.includes(searchInput) ||
        customerFullName?.includes(searchInput?.toLowerCase())
        : true;

      let isDateValid = true;
      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("/");
        return new Date(`${year}-${month}-${day}`);
      };

      if (formattedDates) {
        isDateValid = isDateInRange(
          parseDate(details.policyIssueDate),
          formattedDates
        ); // Apply date range filtering
      }

      return isSearchMatch && isDateValid; // Both conditions must match
    });

    setPolicyDetailsData(filteredQuotes); // Set the filtered data
  };
  useEffect(() => {
    if (value) {
      filterFunction();
    } else {
      setQuoteData(quoteDataOriginal);
    }
  }, [value]);

  useEffect(() => {
    if (valueProposal) {
      filterFunctionPropsal();
    } else {
      setproposalData(proposalDataOriginal);
    }
  }, [valueProposal]);

  useEffect(() => {
    if (valuePolicy) {
      filterFunctionPolicy();
    } else {
      setPolicyDetailsData(policyDetailsDataOriginal);
    }
  }, [valuePolicy]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Adjust for timezone if necessary
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );

    // Format the date parts
    const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const day = String(adjustedDate.getDate()).padStart(2, "0");
    const year = adjustedDate.getFullYear();
    const customHours = "12";
    const customMinutes = "13";
    const customSeconds = "59";
    // Return in the desired format
    return `${month}/${day}/${year} ${customHours}:${customMinutes}:${customSeconds}`;
  };

  function isDateInRange(details, formattedDates) {
    const quoteDate = new Date(details);
    const startDate = new Date(formattedDates[0]);
    const endDate = new Date(formattedDates[1]);
    quoteDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    return quoteDate >= startDate && quoteDate <= endDate;
  }

  return (
    <div className="container-fluid mt-3">
      <div className="tab-wrap">
        <div className="tabs">
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 1 ? "active" : ""}
            onClick={() => updateToggle(1)}
          >
            My Profile{" "}
          </button>
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 2 ? "active" : ""}
            onClick={() => updateToggle(2)}
          >
            Risk and Insurance Profile{" "}
          </button>
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 3 ? "active" : ""}
            onClick={() => updateToggle(3)}
          >
            My Quotes{" "}
          </button>
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 4 ? "active" : ""}
            onClick={() => updateToggle(4)}
          >
            My Proposal{" "}
          </button>

          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 9 ? "active" : ""}
            onClick={() => updateToggle(9)}
          >
            Underwriting Policies{" "}
          </button>

          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 5 ? "active" : ""}
            onClick={() => updateToggle(5)}
            id="Prosposal"
          >
            My Policies{" "}
          </button>

          {/* generate policies */}
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 8 ? "active" : ""}
            onClick={() => updateToggle(8)}
            id="Prosposal" >
            Generate Policy{" "}
          </button>

          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 6 ? "active" : ""}
            onClick={() => {
              // sessionStorage.removeItem("isAdmin")
              // formContext.setloginType()
              sessionStorage.clear();
              updateToggle(6);
              localStorage.removeItem("user");
              alert("Logged Out ");
              window.location.href = "/myaccount";
            }}
          >
            Log Out{" "}
          </button>
        </div>

        <div className="content-wrap">
          <div
            className={
              toggle === 1 ? "showConten" : "content my-profile-content"
            }
          >
            <div className="person-name-wrap">
              <IoPersonCircleOutline
                style={{ width: "100px", height: "100px" }}
              />
              <p className="person-name">
                {initialData?.firstName} {initialData?.lastName}
              </p>
            </div>

            <div className="personal-info-wrap">
              <div className="personal-info">
                <label className="label">Full Name</label>
                <p className="value">
                  {initialData?.firstName} {initialData?.lastName}
                </p>
              </div>
              <div className="personal-info">
                <label className="label">Email Address</label>
                <p className="value">{initialData?.email}</p>
              </div>
              <div className="personal-info">
                <label className="label">Address</label>
                <p className="value">{initialData?.address}</p>
              </div>
              <div className="personal-info">
                <label className="label">Mobile Number</label>
                <p className="value">{mobile}</p>
              </div>
              <div className="personal-info">
                <label className="label">Gender</label>
                <p className="value">{initialData?.gender}</p>
              </div>
              <div className="personal-info">
                <label className="label">Marital status</label>
                <p className="value">{initialData?.maritalStatus}</p>
              </div>
            </div>

            {/* {!isEditing && (
              <Button
                className="primary-btn"
                style={{ marginRight: "5px" }}
                type="submit"
                onClick={handleEdit}
              >
                Edit
              </Button>
            )} */}
          </div>

          <div
            className={
              toggle === 2 ? "showConten" : "content insurance-profile"
            }
          >
            <img src={Progress} width={"40%"} />
          </div>

          <div className={toggle === 3 ? "showConten" : "content"}>
            <div className="quotes">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParams.searchInput}
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />{" "}
                <button
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      searchInput: "",
                    });
                    setQuoteData(quoteDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunction();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker value={value} onChange={setValue} />
              </div>

              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th>Insured Name</th>
                    <th>Insured Mobile No</th>
                    <th>Insured Email</th>
                    <th>Quote Number</th>
                    <th>Quote Date</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {quoteData === undefined ||
                  quoteData === null ||
                  quoteData?.length === 0
                  ? (loaderStatus && (
                    <tr>
                      <td colSpan={7}>
                        <Spinner animation="border" variant="danger" />
                      </td>
                    </tr>
                  )) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                  : quoteData?.map((details, index) => {
                    return (
                      details.phoneNumber && (
                        <tbody key={index}>
                          <tr>
                            <td>
                              {details.firstName} {details.lastName}
                            </td>
                            <td>{details.phoneNumber}</td>
                            <td>{details.email}</td>
                            <td>{details.QUOTE_ID}</td>
                            <td> {new Date(details.quoteCreatedDate).toLocaleDateString('en-GB')}</td>
                            <td>{details.sysPolicyStartDate}</td>
                            <td>{details.sysPolicyEndDate}</td>
                            <td>
                              <Button
                                className="primary-btn"
                                onClick={() =>
                                (window.location.href =
                                  "/quotes?quoteID=" + details.QUOTE_ID)
                                }
                              >
                                Resume
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )
                    );
                  })}
              </Table>
            </div>
          </div>

          <div className={toggle === 4 ? "showConten" : "content"}>
            <div className="policies">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForProposal.searchInput}
                  onChange={(e) =>
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />{" "}
                <button
                  onClick={() => {
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: "",
                    });
                    setproposalData(proposalDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPropsal();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker
                  value={valueProposal}
                  onChange={setValueProposal}
                />
              </div>

              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Proposal Date</th>
                    <th>Insured Name</th>
                    <th>Proposal Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>Breakin Status</th>
                    <th>Premium(RS)</th>
                    <th>Type</th>
                    <th>Action</th>
                    {initialData?.id === null ? null : <th>Share</th>}

                    <th></th>
                  </tr>
                </thead>
                {proposalData === undefined ||
                  proposalData === null ||
                  proposalData?.length === 0
                  ? (loaderStatus && (
                    <tr>
                      <td colSpan={7}>
                        <Spinner animation="border" variant="danger" />
                      </td>
                    </tr>
                  )) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                  : initialData?.id === null || initialData?.id === undefined
                    ? proposalData?.map((details, index) => {
                      return (
                        !details.policyNo &&
                        !details.policyIssueDate && (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <div className="policy-image">
                                  <img
                                    src={
                                      imgArrayForCard?.[
                                      details?.insuranceCompany
                                      ]
                                    }
                                    alt=""
                                    className="logo"
                                    style={
                                      details?.insurerName ===
                                        "HDFC ERGO General Insurance Company"
                                        ? { width: "120px", height: "44px" }
                                        : null
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {details?.proposalCreatedDate.split(" ")?.[0]}
                              </td>
                              <td>
                                {details?.firstName + " " + details?.lastName}
                              </td>
                              <td>{details?.proposalId}</td>
                              <td>{details?.policyStartDate}</td>
                              <td>{details?.policyExpiryDate}</td>
                              <th>{details?.breakinStatusRes?.status || "NA"}</th>
                              <td>{details?.totalPremium}</td>
                              <td>
                                {" "}
                                <div>
                                  <img
                                    src={
                                      details?.proposalResponseCar
                                        ?.businessLineId === 3
                                        ? car
                                        : bike
                                    }
                                    alt=""
                                    className="logo"
                                    style={{ width: "100px", height: "54px" }}
                                  />
                                </div>
                              </td>
                              <td>
                                <Button
                                  className="primary-btn"
                                  onClick={() => {
                                    getProposalDetsil(details?.proposalId);
                                    setbuyNowIndex(index);
                                    setbuyNowLoader(true);
                                  }}
                                >
                                  Buy{" "}
                                  {buyNowLoader &&
                                    buyNowIndex === index &&
                                    formContext.spinner}
                                </Button>
                              </td>
                              <td> </td>
                            </tr>
                          </tbody>
                        )
                      );
                    })
                    : proposalData?.map((details, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              {/* {details?.PolicyTransaction?.proposalRequest?.premiumDetails?.insuranceCompany} */}
                              <div className="policy-image">
                                <img
                                  src={
                                    imgArrayForCard?.[
                                    details?.PolicyTransaction
                                      ?.proposalRequest?.premiumDetails
                                      ?.insuranceCompany
                                    ]
                                  }
                                  alt={
                                    details?.PolicyTransaction?.proposalRequest
                                      ?.premiumDetails?.insuranceCompany
                                  }
                                  className="logo"
                                  style={
                                    details?.PolicyTransaction?.proposalRequest
                                      ?.premiumDetails?.insuranceCompany ===
                                      "HDFC ERGO General Insurance Company"
                                      ? { width: "120px", height: "44px" }
                                      : null
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {
                                details?.PolicyTransaction?.proposalCreatedDate.split(
                                  " "
                                )?.[0]
                              }
                            </td>
                            <td>
                              {details?.PolicyTransaction?.firstName +
                                " " +
                                details?.PolicyTransaction?.lastName}
                            </td>
                            <td>{details?.PolicyTransaction?.proposalId}</td>
                            <td>
                              {details?.PolicyTransaction?.policyStartDate}
                            </td>
                            <td>
                              {details?.PolicyTransaction?.policyExpiryDate}
                            </td>
                            <td>{details?.PolicyTransaction?.totalPremium}</td>
                            <td>{details?.breakinStatusRes?.status || "NA"}</td>
                            <td>
                              {" "}
                              <div>
                                <img
                                  src={
                                    details?.PolicyTransaction
                                      ?.carProposalResponseCar
                                      ?.businessLineId === 3
                                      ? car
                                      : bike
                                  }
                                  alt=""
                                  className="logo"
                                  style={{ width: "100px", height: "54px" }}
                                />
                              </div>
                            </td>
                            <td>
                              <Button
                                className="primary-btn"
                                onClick={() => {
                                  getProposalDetsil(
                                    details?.PolicyTransaction?.proposalId
                                  );
                                  setbuyNowIndex(index);
                                  setbuyNowLoader(true);
                                }}
                              >
                                Buy{" "}
                                {buyNowLoader &&
                                  buyNowIndex === index &&
                                  formContext.spinner}
                              </Button>
                            </td>
                            <td>
                              <div className="d-flex">
                                {/* <Button className="share-quotes-btn">
                             
                                  <SVG
                                    src={Share}
                                    alt=""
                                    className="share-icon"
                                  />{" "}
                                  Share Quotes
                                </Button> */}

                                <div className="Demo__container share-quote-content">
                                  <div
                                    className="Demo__some-network social-icon"
                                    title="Email"
                                  >
                                    <EmailShareButton
                                      url={
                                        shareUrl +
                                        `/proposal/2/` +
                                        details?.PolicyTransaction?.proposalId
                                      }
                                      subject={title}
                                      body="body"
                                      className="Demo__some-network__share-button"
                                    >
                                      <EmailIcon size={32} round />
                                    </EmailShareButton>
                                  </div>

                                  <div
                                    className="Demo__some-network "
                                    onClick={closeShareQuptes}
                                    title="Whatsapp"
                                  >
                                    <WhatsappShareButton
                                      url={
                                        shareUrl +
                                        `/proposal/2/` +
                                        details?.PolicyTransaction?.proposalId
                                      }
                                      title={title}
                                      separator=":: "
                                      className="Demo__some-network__share-button"
                                    >
                                      <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
              </Table>
            </div>
          </div>

          <div className={toggle === 9 ? "showConten" : "content"}>
            <div className="policies">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForProposal.searchInput}
                  onChange={(e) =>
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                <button
                  onClick={() => {
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: "",
                    });
                    setproposalData(proposalDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPropsal();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker value={valueProposal} onChange={setValueProposal} />
              </div>

              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Proposal Date</th>
                    <th>Insured Name</th>
                    <th>Proposal Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>Premium(RS)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {proposalData === undefined || proposalData === null || proposalData?.length === 0
                  ? loaderStatus ? (
                    <tr>
                      <td colSpan={7}>
                        <Spinner animation="border" variant="danger" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                  : proposalData?.map((details, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <div className="policy-image">
                              <img
                                src={
                                  imgArrayForCard?.[details?.insuranceCompany]
                                }
                                alt=""
                                className="logo"
                                style={
                                  details?.insurerName === "HDFC ERGO General Insurance Company"
                                    ? { width: "120px", height: "44px" }
                                    : null
                                }
                              />
                            </div>
                          </td>
                          <td>
                            {details?.proposalCreatedDate?.split(" ")?.[0]}
                          </td>
                          <td>
                            {details?.firstName + " " + details?.lastName}
                          </td>
                          <td>{details?.proposalId}</td>
                          <td>{details?.policyStartDate}</td>
                          <td>{details?.policyExpiryDate}</td>
                          <td>{details?.totalPremium || "NA"}</td>
                          <td>{details?.healthProposalStatus || "Pending"}</td>
                          <td>
                            <Button
                              className="primary-btn"
                              style={{ width: "158px" }}
                              onClick={() => {
                                setbuyNowLoader(true);
                                api.carePolicyStatusService(details?.proposalId)
                                  .then((response) => {
                                    const policyStatus = response?.data?.data?.carrierResponse?.intGetPolicyStatusIO?.policyStatus;
                                    console.log("Policy Status:", policyStatus);

                                    if (policyStatus === "Inforce") {
                                      console.log("proposal ID", details?.proposalId);
                                      console.log("checking proposal data...", proposalData);
                                      const updatedProposalData = proposalData.filter(
                                        (policy) => policy.proposalId !== details?.proposalId
                                      );
                                      setproposalData(updatedProposalData);
                                      console.log("moving to the account tab")
                                      // formContext.notifySuccess("Policy is Inforce and has been removed from the list.");
                                      setModalMessage("Policy is Inforce.");
                                      handleModalShow();
                                      window.location.href = "/myaccount?tab=5";
                                    } else if (
                                      policyStatus === 'Pending Application Entry' || 'Pending CPU Requirement' || 'Pending Tele Q'
                                    ) {
                                      setModalMessage("Pending with Underwriting Team for Review");
                                      console.log("setModalMessage", modalMessage)
                                      handleModalShow();
                                    } else {
                                      // formContext.notifyError(`Policy Status: ${policyStatus}`);
                                      setModalMessage(`Policy Status: ${policyStatus}`);
                                      handleModalShow();
                                    }
                                    setbuyNowIndex(index);
                                    setbuyNowLoader(false);
                                  })
                                  .catch((error) => {
                                    formContext.notifyError("Error while fetching policy status");
                                    setbuyNowLoader(false);
                                  });
                              }}
                            >
                              Check Status
                              {buyNowLoader && buyNowIndex === index && formContext.spinner}
                            </Button>
                         
                            <Modal show={showModal} onHide={handleModalClose} centered className="custom-modal">
                              <Modal.Header closeButton className="modal-header">
                                <Modal.Title style={{ fontSize: '17px' }}>Policy Status</Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="modal-body">{modalMessage}</Modal.Body>
                              <Modal.Footer className="modal-footer">
                                <Button variant="secondary" onClick={handleModalClose} className="close-btn">
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                       
                          </td>
                          <td>
                          <div className="Demo__container share-quote-content">
                              <div
                                className="Demo__some-network social-icon"
                                title="Email"
                              >
                                <EmailShareButton
                                  url={
                                    shareUrl +
                                    `/proposal/2/` +
                                    details?.PolicyTransaction?.proposalId
                                  }
                                  subject={title}
                                  body="body"
                                  className="Demo__some-network__share-button"
                                >
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                              </div>

                              <div
                                className="Demo__some-network "
                                onClick={closeShareQuptes}
                                title="Whatsapp"
                              >
                                <WhatsappShareButton
                                  url={
                                    shareUrl +
                                    `/proposal/2/` +
                                    details?.PolicyTransaction?.proposalId
                                  }
                                  title={title}
                                  separator=":: "
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </div>

                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </Table>
            </div>
          </div>

          <div className={toggle === 5 ? "showConten my-policies" : "content "}>
            {policyCount && (
              <span style={{ justifyContent: "left", fontWeight: "bold" }}>
                Total Policies : {policyCount}
              </span>
            )}
            <div className="policies">
              <div
                style={{
                  marginTop: "10px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForPolicy.searchInput}
                  onChange={(e) =>
                    setSearchParamsForPolicy({
                      ...searchParamsForPolicy,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />{" "}
                <button
                  onClick={() => {
                    setSearchParamsForPolicy({
                      ...searchParamsForPolicy,
                      searchInput: "",
                    });
                    setPolicyDetailsData(policyDetailsDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPolicy();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker
                  value={valuePolicy}
                  onChange={setValuePolicy}
                />
              </div>
              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Purchased on</th>
                    <th>Insured Name</th>
                    <th>Mobile Number</th>
                    <th>Policy Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>Action</th>
                    <th>Share</th>
                  </tr>
                </thead>
                {policyDetailsData === undefined ||
                  policyDetailsData?.length === 0 ||
                  policyDetailsData === null
                  ? (formContext.loaderStatus && (
                    <tr>
                      <td colSpan={7}>
                        <Spinner animation="border" variant="danger" />
                      </td>
                    </tr>
                  )) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                  : initialData?.id === null || initialData?.id === undefined
                    ? policyDetailsData?.map((details) => {
                      return (
                        <tbody>
                          <tr>
                            <td>
                              <div className="policy-image">
                                <img
                                  src={imgArrayForCard?.[details?.insurerName]}
                                  alt=""
                                  className="logo"
                                  style={
                                    { width: "120px", height: "44px !important" }
                                  }
                                />
                              </div>
                            </td>
                            <td>{details?.policyIssueDate}</td>
                            <td>{details?.Name}</td>
                            <td>{details?.mobile}</td>
                            <td>
                              {details?.policyNo || details?.policyNumber}
                            </td>
                            <td>{details?.policyStartDate}</td>
                            <td>{details?.policyExpiryDate}</td>
                            <td>
                              <Button
                                className="primary-btn"
                                onClick={() => {
                                  // if (details.carrierId === 61) {
                                  //   window.open(details.filePath);
                                  // } else {
                                  downloadFileFromAPI(
                                    details?.carrierId,
                                    details?.mobile,
                                    details?.secretKey
                                  );
                                  // }
                                }}
                              >
                                Download
                              </Button>
                            </td>
                            <td>
                              <div className="d-flex">
                                <NavDropdown
                                  className="custom-dropdown"
                                  open={activeRow}
                                  title={<CiShare2 />}
                                  onClick={() => {
                                    // if (details.carrierId === 61) {
                                    //   window.open(details.filePath);
                                    // } else {
                                    downloadFileFromAPI(
                                      details?.carrierId,
                                      details?.mobile,
                                      details?.secretKey
                                    );
                                    // }
                                  }}
                                >
                                  <div className="Demo__container share-quote-content">
                                    <div
                                      className="Demo__some-network social-icon"
                                      title="Email"
                                    >
                                      <EmailShareButton
                                        url={policyDownloadLink}
                                        subject="Click on the link below to download the policy PDF"
                                        body="body"
                                        className="Demo__some-network__share-button"
                                      >
                                        <EmailIcon size={32} round />
                                      </EmailShareButton>
                                    </div>

                                    <div
                                      className="Demo__some-network "
                                      onClick={closeShareQuptes}
                                      title="Whatsapp"
                                    >
                                      <WhatsappShareButton
                                        url={policyDownloadLink}
                                        title="Click on the link below to download the policy PDF"
                                        separator=":: "
                                        className="Demo__some-network__share-button"
                                      >
                                        <WhatsappIcon size={32} round />
                                      </WhatsappShareButton>
                                    </div>
                                  </div>
                                </NavDropdown>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                    : policyDetailsData?.map((details, index) => {
                      return (
                        <tbody>
                          <tr>
                            <td>
                              <div className="policy-image">
                                <img
                                  src={imgArrayForCard?.[details?.insurerName]}
                                  alt=""
                                  className="logo"
                                  style={
                                    details?.insurerName ===
                                      "HDFC ERGO General Insurance Company"
                                      ? { width: "120px", height: "44px" }
                                      : null
                                  }
                                />
                              </div>
                            </td>
                            <td>{details?.policyIssueDate}</td>
                            <td>{details?.Name}</td>
                            <td>{details?.mobile}</td>
                            <td>
                              {details?.policyNo || details?.policyNumber}
                            </td>
                            <td>{details?.policyStartDate}</td>
                            <td>{details?.policyExpiryDate}</td>
                            <td>
                              <Button
                                className="primary-btn"
                                onClick={() => {
                                  downloadFileFromAPI(
                                    details?.carrierId,
                                    details?.mobile,
                                    details?.secretKey
                                  );
                                }}
                              >
                                Download
                              </Button>
                            </td>
                            <td>
                              <div className="d-flex">
                                <NavDropdown
                                  className="custom-dropdown"
                                  open={activeRow}
                                  title={<CiShare2 />}
                                  onClick={() =>
                                    createLinkForSharePolicy(
                                      details?.carrierId,
                                      details?.mobile,
                                      details?.secretKey,
                                      index
                                    )
                                  }
                                >
                                  <div className="Demo__container share-quote-content">
                                    <div
                                      className="Demo__some-network social-icon"
                                      title="Email"
                                    >
                                      <EmailShareButton
                                        url={policyDownloadLink}
                                        subject={title}
                                        body="body"
                                        className="Demo__some-network__share-button"
                                      >
                                        <EmailIcon size={32} round />
                                      </EmailShareButton>
                                    </div>

                                    <div
                                      className="Demo__some-network "
                                      onClick={closeShareQuptes}
                                      title="Whatsapp"
                                    >
                                      <WhatsappShareButton
                                        url={policyDownloadLink}
                                        title={title}
                                        separator=":: "
                                        className="Demo__some-network__share-button"
                                      >
                                        <WhatsappIcon size={32} round />
                                      </WhatsappShareButton>
                                    </div>
                                  </div>
                                </NavDropdown>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
              </Table>
            </div>
          </div>

          <div className={toggle === 8 ? 'showConten my-policies' : 'content'}>
            <div className="generate-policies">
              <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForPolicy.searchInput}
                  onChange={(e) => setSearchParamsForPolicy({ ...searchParamsForPolicy, searchInput: e.target.value })}
                  style={{
                    width: '250px',
                    marginRight: '10px',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                />
                <button
                  onClick={() => {
                    setSearchParamsForPolicy({ ...searchParamsForPolicy, searchInput: '' });
                    setPolicyDowloadRepository(policyDetailsDataOriginal);
                  }}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPolicy();
                  }}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '5px',
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker value={valuePolicy} onChange={setValuePolicy} />
              </div>

              {/* Loading Spinner and Message */}
              {isLoading && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Spinner animation="border" role="status" />
                  <p>Loading...</p>
                </div>
              )}

              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Proposal Id</th>
                    <th>Registration no</th>
                    <th>Mobile no</th>
                    <th></th>
                  </tr>
                </thead>
                {policyDowloadRepository.length === 0 ? (
                  (formContext.loaderStatus && formContext.spinner) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={'No data found'} />
                      </td>
                    </tr>
                  )
                ) : (
                  policyDowloadRepository.map((details, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>
                          <div className="policy-image">
                            <img
                              src={imgArrayForCard?.[details?.insurerName]}
                              alt=""
                              className="logo"
                              style={
                                details?.insurerName === 'HDFC ERGO General Insurance Company'
                                  ? { width: '120px', height: '44px' }
                                  : null
                              }
                            />
                          </div>
                        </td>
                        <td>{details?.firstName}</td>
                        <td>{details?.lastName}</td>
                        <td>{details?.paymentResponse?.proposalId}</td>
                        <td>{details?.registrationNumber}</td>
                        <td>{details?.mobile}</td>
                        <td>
                          <Button
                            className="primary-btn"
                            style={{ width: '200px' }}
                            onClick={() => handleGeneratePolicy(details)}
                          >
                            Generate Policy
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))
                )}
              </Table>

              <generatePolicyModal
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                  getPolicyDownloadRepository();
                }}
              >
                {generatedPolicyDetails ? (
                  <>
                    <h1 style={{ color: 'green' }}><b>Policy Generated Successfully!</b></h1>
                    <br />
                    <p>Your policy has been successfully generated. Below are the details:</p>
                    <br />
                    <p><strong>Proposal ID:</strong> {generatedPolicyDetails.proposalId}</p>
                    <p><strong>Policy Number:</strong> {generatedPolicyDetails.policyNo}</p>
                  </>
                ) : (
                  <h2>Generating policy .........</h2>
                )}
              </generatePolicyModal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
