import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import UseFormContext from "../../../context/UseFormContext";
import KYCForm from "../../MultipleForms/KYCForm";
import OwnerContactInfo from "../../MultipleForms/OwnerContactInfo";
import Benefits from "../../MultipleForms/Benefits";
import PaymentForm from "../../MultipleForms/PaymentForm";
import { ErrorMessage, useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import UseMultistepForm from "../../MultipleForms/UseMultiStepForms";
import VerticallyCenteredModal from "../../commonModules/Popups/VerticallyCenteredModal";
import { BiSolidError } from "react-icons/bi";
import SVG from "react-inlinesvg";
import Done from "../../../images/Done.svg";
import { useHistory } from "react-router-dom";
import RiderSelection from "./RiderSelection";
import MemberDetailis from "./MemberDetails";
import AddressDetails from "./AddressDetails";
import { paymentApi } from "../../../API/authCurd";
import ContactInfo from "./ContactInfo";
// import Nominee from "./Nominee";
import MedicalQuestionnaire from "./MedicalQuestionnaire";
import { careKycVerify, careKycOVD } from "../../../API/kyc/care-kyc.service";
import Nominee from "./Nominee";
import { submitHealthProposalApi } from "../../../API/authCurd";
import { yearValidation, convertDate } from "../../commonModules/CommonCode";
import PremiumBreakup from "./BreakUpPage";
import { Container, Row, Col, Table } from "react-bootstrap";
import FileUpload from "../../commonModules/FileUpload";
import { gateage } from '../../commonModules/CommonCode';

const MultistepProposal = (props) => {
  const formContext = UseFormContext();
  const [consentGiven, setConsentGiven] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const paymentdataDestructure = {};
  const history = useHistory();
  const [iframeUrl, setiframeUrl] = useState();
  const [iframeStatus, setiframeStatus] = useState(false);
  const [iframeOpenedCarrier, setiframeOpenedCarrier] = useState();
  const [kycDetails, setKYCDetails] = useState();
  const [proposalResponse, setProposalResponse] = useState();
  const [showBreakupPopup, setshowBreakupPopup] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [kycPopUpStatus, setkycPopUpStatus] = useState(false);
  const [isCareKYCVerified, setISCareKYCVerified] = useState(false)
  const [kycPopUp, setkycPopUp] = useState(false);
  const [carekycError, setcarekycError] = useState(false);
  const [proposalNumber, setProposalNumber] = useState()
  const [submissionError, setSubmissionError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [careKycData, setcareKycData] = useState({
    poiFile: "", //proof of identity
    poaFile: "",
    poitype: "",
    poatype: "",
    poiBase64: "",
    poaBase64: ""
  });
  const [poiType, setpoiType] = useState([
    { value: "", label: "Please select" },
    { value: "PAN", label: "PAN" },
    { value: "AADHAAR", label: "AADHAAR" },
    { value: "VOTERID", label: "VOTERID" },
    { value: "DL", label: "DRIVING LICENSE" },
    { value: "PASSPORT", label: "PASSPORT" },
  ]);

  const [poAType, setpoAType] = useState([
    { value: "", label: "Please select" },
    { value: "AADHAAR", label: "AADHAAR" },
    { value: "VOTERID", label: "VOTERID" },
    { value: "DL", label: "DRIVING LICENSE" },
    { value: "PASSPORT", label: "PASSPORT" },
  ]);
  const [mem, setMem] = useState([
    {
      memberFirstName: "",
      memberLastName: "",
      memberDateOfBirth: "",
      memberHeightcm: "",
      memberHeightfeet: "",
      memberHeightinches: "",
      memberSalutation: "",
      memberWeight: "",
      memberGender: "",
      // memberRole: "",
      memberRelation: "",
      memberEmailId: "",
      memberMobileNo: "",
      memberPanNumber: "",
      memberpermLine1: "",
      memberpermLine2: "",
      memberpermLine3: "",
      memberpermCity: "",
      memberpermDist: "",
      memberpermState: "",
      memberpermCountry: "",
      memberpermPin: "",
      membercorresLine1: "",
      membercorresLine2: "",
      membercorresCity: "",
      membercorresState: "",
      membercorresDist: "",
      membercorresCountry: "",
      membercorresPin: "",
      memberSelectedDisease: [
        {
          diseaseName: "",
          questionId: "",
        },
      ],
      memberSelectedDiseaseQuestions: [
        {
          questionSetCd: "",
          questionCd: "",
          response: "",
        },
      ],
    },
  ]);

  const [basePremium, setBasePremium] = useState(5000);
  const [selectedRiders, setSelectedRiders] = useState([
    { riderName: "Unlimited Automatic Recharge", amount: 1000 },
    { riderName: "Pre and Post Hospitalization", amount: 800 },
  ]);
  const [taxRate, setTaxRate] = useState(18)
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const totalRiderAmount = selectedRiders.reduce((total, rider) => total + rider.amount, 0);
  const taxAmount = ((basePremium + totalRiderAmount) * taxRate) / 100;
  const totalPremium = basePremium + totalRiderAmount + taxAmount;


  useEffect(() => {
    const isPaymentApiTriggered = sessionStorage.getItem('paymentApiTriggered');
    if (isPaymentApiTriggered === 'false') {
      triggerPaymentApi();
    }
  }, []);

  const triggerPaymentApi = () => {
    // Call the payment API here
    paymentApi({ totalPremium })
      .then((response) => {
        console.log('Payment API response:', response);
        sessionStorage.setItem('paymentApiTriggered', 'true'); // Mark that the API has been triggered
      })
      .catch((error) => {
        console.error('Payment API failed:', error);
      });
  };
  useEffect(() => {
    console.log(
      "props.healthQuoteFormikData.healthInsurefor",
      props?.healthQuoteFormikData?.healthInsurefor
    );
    const updatedMem = props.healthQuoteFormikData.healthInsurefor.map(
      (member) => ({
        memberFirstName: "",
        memberLastName: "",
        memberDateOfBirth: member.dateOfBirth || "",
        memberHeightcm: "",
        memberHeightfeet: "",
        memberHeightinches: "",
        memberSalutation: "",
        memberWeight: "",
        memberEmailId: "",
        memberMobileNo: "",
        memberGender: "",
        // memberRole: "",
        memberRelation: member.relation || "",
        memberPanNumber: "",
        memberpermLine1: "",
        memberpermLine2: "",
        memberpermLine3: "",
        memberpermCity: "",
        memberpermDist: "",
        memberpermState: "",
        memberpermCountry: "",
        memberpermPin: "",
        membercorresLine1: "",
        membercorresLine2: "",
        membercorresCity: "",
        membercorresState: "",
        membercorresDist: "",
        membercorresCountry: "",
        membercorresPin: "",
        memberSelectedDisease: [
          {
            diseaseName: "",
            questionId: "",
          },
        ],
        memberSelectedDiseaseQuestions: [
          {
            questionSetCd: "",
            questionCd: "",
            response: "",
          },
        ],
      })
    );

    setMem(updatedMem);
  }, [props?.healthQuoteFormikData?.healthInsurefor]);

  const initialDataForProposal = {
    policyDetails: {
      sumInsured: "",
      policyTerm: "",
      basePremium: "",
      TotalPremium: "",
      IsPort: "",
      ridersList: "",
      addOns: "",
    },
    proposerDetails: {
      firstName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      mobileNumber: "",
      emailId: "",
      panNumber: "",
      aadharNumber: "",
      maritalStatus: "",
      fullNameAsPerAadhar: "",
      personAge: "",
      isAddressSameAsCommun: true,
      salutation: "",
      kycBase64Code: "",
      kycDocumentType: "",
      ckycNo: "",
      IsConfirm: "",


      communicationAddress: {
        comArea: "",
        comPincode: "",
        comCity: "",
        comDisplayArea: "",
        comDistrict: "",
        comDisplayField: "",
        comState: "",
        comDoorNo: "",
      },
    },
    nominationDetails: {
      nomPersonAge: "",
      nomFirstName: "",
      nomLastName: "",
      nomDateOfBirth: "",
      nominationRelation: "",
      nominationRelationId: "",
    },
    addressDetails: {
      isAddressSameAsKYC: true,
      area: "",
      pincode: "",
      city: "",
      displayArea: "",
      district: "",
      displayField: "",
      state: "",
      doorNo: "",
    },
    carrierId: "",
    planId: "",
    policyType: "",
    QUOTE_ID: sessionStorage.getItem("quoteId"),
    businessLineId: "",
    campaign_id: "",
  };


  const initialData = {
    ...initialDataForProposal,
    selectedCarrier: props.selectedHealthQuoteData?.insuranceCompany,
    currentStepIndex: "0",
    policyTerm: props.healthQuoteFormikData?.policyTerm,
    sumInsured: props.healthQuoteFormikData?.sumInsured,
    kycType: "",
    kycNominePanNumber: "",
    kycNomineAadharNumber: "",
    kycNomineDob: "",
    kycNomineGender: "",
    kycNomineFullName: "",
    ownerPrefix: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerPhoneNumber: "",
    ownerMaritialStus: "",
    ownerGstin: "",
    nomineFirstName: "",
    nomineLastName: "",
    nomineDob: "",
    nomineRelation: "",
    appointeeName: "",
    appointeeRelation: "",
    appointeeDOB: "",
    bankAccountNumber: "",
    bankIFSCCode: "",
    familyDetails: props?.healthQuoteFormikData?.healthInsurefor?.map(
      (member) => {
        if (member.relation === "Self") {
          return {
            id: member.id,
            name:
              props.healthMainFormData?.firstName +
              " " +
              props.healthMainFormData?.lastName,
            gender: props.healthMainFormData?.gender,
            heightFt: "",
            heightIn: "",
            weight: "",
            age: member.age ?? "",
            dateOfBirth: member.dateOfBirth ?? "",
            relation: member.relation ?? "",
            memberEmailId: member.memberEmailId || "",
            memberMobileNo: member.memberMobileNo || "",
            memberPanNumber: member.memberPanNumber || "",
            memberpermLine1: member.memberpermLine1 || "",
            memberpermLine2: member.memberpermLine2 || "",
            memberpermLine3: member.memberpermLine3 || "",
            memberpermCity: member.memberpermCity || "",
            memberpermDist: member.memberpermDist || "",
            memberpermState: member.memberpermState || "",
            memberpermCountry: member.memberpermCountry || "",
            memberpermPin: member.memberpermPin || "",
            membercorresLine1: member.membercorresLine1 || "",
            membercorresLine2: member.membercorresLine2 || "",
            membercorresCity: member.membercorresCity || "",
            membercorresState: member.membercorresState || "",
            membercorresDist: member.membercorresDist || "",
            membercorresCountry: member.membercorresCountry || "",
            membercorresPin: member.membercorresPin || "",
          };
        } else {
          return {
            id: member.id,
            name: "",
            heightFt: "",
            heightIn: "",
            weight: "",
            gender: "",
            age: member.age ?? "",
            dateOfBirth: member.dateOfBirth ?? "",
            relation: member.relation ?? "",
          };
        }
      }
    ),
    medicalQuestionnaire: "",
    ownerFlatNumber: "",
    ownerAddress: "",
    ownerLocation: "",
    ownerPincode: "",
    ownerCity: "",
    ownerState: "",
    isRegisteredAddressSame: false,
    ownerFlatNumberReg: "",
    ownerAddressReg: "",
    ownerLocationReg: "",
    ownerPincodeReg: "",
    ownerCityReg: "",
    ownerStateReg: "",
  };

  const returnData = (file, previewURL, type) => {
    console.log("OVD", { file, previewURL })

    if (type === 1) {
      setcareKycData({ ...careKycData, poiFile: file, poiBase64: previewURL });
    } else {
      setcareKycData({ ...careKycData, poaFile: file, poaBase64: previewURL });
    }
  };
  const docTypeChanges = (value, type) => {
    console.log({ value, type })

    if (type === 1) {
      setcareKycData({ ...careKycData, poitype: value });
    } else {
      setcareKycData({ ...careKycData, poatype: value });
    }
  };

  const careDocKycSubmit = () => {
    Promise.all([careKycOVD(careKycData.poiFile.name, careKycData.poiBase64, "Identity Proof",
      proposalNumber), careKycOVD(careKycData.poaFile.name, careKycData.poaBase64, "Address Proof", proposalNumber)])
      .then(values => {
        let isOVDFailed = false
        console.log("values", values)
        values.forEach((res) => {
          const ovdResponse = JSON.parse(res.data.data);
          console.log("OVD response", ovdResponse);
          if (ovdResponse?.responseData?.status === "0") {
            isOVDFailed = true;
            //display error message
          }
        })
        if (!isOVDFailed) {
          setkycPopUp(false)
          setshowBreakupPopup(true)
        }
      })
      .catch((err) => {
        console.log("error", err)
      })


  }

  const validationSchema = Yup.object().shape({
    currentStepIndex: Yup.string().required("Field is required"),

    //   kycType: Yup.string()
    //   .when("currentStepIndex",{
    //     is:"1",
    //     then:Yup.string()
    //     .required("KYC Document Type is required")
    //     .oneOf(["pan", "aadhar"], "Invalid KYC Document Type"),
    //   }),

    // kycNomineAadharNumber: Yup.string()
    // .when("kycType", {
    //   is: "aadhar",
    //   then: Yup.string()
    //     .required("Last 4 digits of Aadhar are required")
    //     .matches(/^\d{4}$/, "Aadhar must be exactly 4 digits"),
    // }),

    // First page
    kycNominePanNumber: Yup.string()
      .when(["kycType", "currentStepIndex"], {
        is: (kycType, currentStepIndex) => kycType === "pan" && currentStepIndex === "1",
        then: (schema) =>
          schema
            .trim()
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
            .required("PAN Number Is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),

    //   kycType: Yup.string()
    //   .required("KYC Document Type is required"),

    //   kycNomineAadharNumber: Yup.string()
    // .when(["kycType", "currentStepIndex"], {
    //   is: (kycType, currentStepIndex) => kycType === "aadhar" && currentStepIndex === "1",
    //   then: (schema) =>
    //     schema
    //       .trim()
    //       .matches(/^\d{4}$/, "Aadhaar Number must be exactly 4 digits")
    //       .required("Aadhaar Number Is Required"),
    //   otherwise: (schema) => schema.notRequired(),
    // }),

    kycNomineDob: Yup.string()
      .when('currentStepIndex', {
        is: "1",
        then: (schema) => schema
          .trim()
          .required("DOB Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 100 years",
            (value) => {
              return yearValidation(value, 100);
            }
          ),
        otherwise: (schema) => schema.notRequired(),
      }),


    // .oneOf(["pan", "aadhar"], "Invalid KYC Document Type"),


    kycNomineGender: Yup.string()
      .when('currentStepIndex', {
        is: "1",
        then: (schema) => schema
          .trim()
          .required("Gender Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
        otherwise: (schema) => schema.notRequired(),
      }),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .email("Invalid Email Address")
          .required(" Email Is Required"),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    //memberDetails

    familyDetails: Yup.array().when("currentStepIndex", {
      is: "3",
      then: (schema) =>
        schema.of(
          Yup.object({
            name: Yup.string().required("Name is required"),  // Name validation
            gender: Yup.string().required("Gender is required"), // Gender validation
            heightFt: Yup.string().required("Height (Feet) is required"), // Height (Feet) validation
            heightIn: Yup.string().required("Height (Inches) is required"), // Height (Inches) validation
            weight: Yup.number()
              .positive("Weight must be a positive number")
              .required("Weight is required"), // Weight validation
            dateOfBirth: Yup.date().required("Date of Birth is required"), // Date of Birth validation
          })
        ),
    }),

    bankAccountNumber: Yup.string()
      .when(["currentStepIndex", "selectedCarrier"], {
        is: (currentStepIndex, selectedCarrier) => currentStepIndex === "3" &&
          selectedCarrier === "Care Health Insurance",
        then: (schema) => schema
          .trim()
          .matches(/^[A-Z0-9]*$/, "Invalid Account Number")
          .required('Bank account number is required'),
        else: (schema) => schema.notRequired(),
      }),

    bankIFSCCode: Yup.string()
      .when(["currentStepIndex", "selectedCarrier"], {
        is: (currentStepIndex, selectedCarrier) => currentStepIndex === "3" &&
          selectedCarrier === "Care Health Insurance",
        then: (schema) => schema
          .trim()
          .matches(/^[A-Z]{4}0[0-9A-Z]{6}$/, "Invalid IFSC Code")
          .required('Bank IFSC Code is required'),
        else: (schema) => schema.notRequired(),
      }),


    //nominee
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "5",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    appointeeName: Yup.string()
      .when(["currentStepIndex", "nomineDob"], {
        is: (currentStepIndex, nomineDob) => currentStepIndex === "5" && 
          gateage(nomineDob) < 18,
        then: (schema) =>  schema.required('Appointee Name is required'),
        else: (schema) => schema.notRequired()
      }),
    appointeeRelation: Yup.string()
      .when(["currentStepIndex", "nomineDob"], {
        is: (currentStepIndex, nomineDob) => currentStepIndex === "5" &&
          gateage(nomineDob) < 18,
        then: (schema) => schema.required('Appointee Relation is required'),
        else: (schema) => schema.notRequired()
      }),
    appointeeDOB: Yup.string()
      .when(["currentStepIndex", "nomineDob"], {
        is: (currentStepIndex, nomineDob) => currentStepIndex === "5" &&
          gateage(nomineDob) < 18,
        then: (schema) => schema.required('Appointee DOB is required'),
        else: (schema) => schema.notRequired()
      }),

    // 4th page
    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\s]+$/,
            "Flat Number must be alphanumeric (letters and numbers only)"
          )
          .required("Flat Number is required"),
    }),

  ownerAddress: Yup.string()
   .when("currentStepIndex", {
     is: "6",
     then: () => Yup.string()
       .required("Address is required")
      //  .max(120, "Address cannot be longer than 120 characters"), // max length validation
   }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().required("Field is required"),

 
    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\s]+$/,
            "Reg Flat Number must be alphanumeric (letters and numbers only)"
          )
          .required("Reg Flat Number is required"),
    }),


    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),

    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),

    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),

    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "6",
      then: () => Yup.string().required("Reg State Is Required"),
    }),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => { },
  });
  console.log(JSON.stringify(formik.errors, null, 2));

  const handleButtonClick = async () => {
    setLoading(true); 
    setError(""); 
  }
  const handleNextSubmit = async (values) => {
    try {
      // Perform validation with the values
      const validationErrors = await formik.validateForm(values);

      console.log("Validation Errors:", validationErrors); // Logging validation errors

      // Check if there are any errors
      if (Object.keys(validationErrors).length === 0 && !formik.isValidating) {
        // If no errors and validation is not in progress, proceed with form submission

        // Check for first or second step
        if (!isLastStep) {
          console.log("in last");


          if (isSecondStep) {
            console.log("in second", formContext?.selectedHealthQuoteData?.insuranceCompany);

            if (formContext?.selectedHealthQuoteData?.insuranceCompany === "Care Health Insurance") {
              console.log("Initiating KYC verification for Care Health Insurance", values);

              // Call the KYC verification function here
              try {
                const response = await careKycVerifyAPI(values);
                console.log("Response-------->", response);
                let kycDetails = JSON.parse(response).getCkycEkycInputIO?.kycDetails?.personalIdentifiableData?.personalDetails;
                if (!kycDetails) {
                  kycDetails = JSON.parse(response).aadharCKYCDetailsIO?.kycDetails?.personalIdentifiableData?.personalDetails;
                }
                console.log("details ", kycDetails)

                if (kycDetails) {
                  setKYCDetails(kycDetails);
                  setISCareKYCVerified(true)
                }

                next();
              } catch (error) {
                console.log("KYC Verification failed:", error);
                alert("KYC varification failed ! Please check your details and try again!")
                formik.setSubmitting(false);
                // formContext.setloaderStatus(false)
                next()
                // setKycPopUpStatus(true); // Show the error message popup
              }
            } else {
              next();
            }
          } else {
            next();
          }
        }

        // Handle last step form submission if applicable
        if (isLastStep) {
          formContext.setloaderStatus(true);
          apiCalls(formik.values);
        }
      } else {
        formik.setSubmitting(false);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };


  useEffect(() => {
    console.log("KYC details popped up", kycDetails);
    formik.setFieldValue("constituitonType", kycDetails?.constituitonType);
    formik.setFieldValue("accountType", kycDetails?.accountType);
    formik.setFieldValue("ckycNo", kycDetails?.ckycNo);
    formik.setFieldValue("prefix", kycDetails?.prefix);
    formik.setFieldValue("firstName", kycDetails?.firstName);
    formik.setFieldValue("lastName", kycDetails?.lastName);
    formik.setFieldValue("fullName", kycDetails?.fullName);
    formik.setFieldValue("fatherPrefix", kycDetails?.fatherPrefix);
    formik.setFieldValue("fatherFname", kycDetails?.fatherFname);
    formik.setFieldValue("fatherFullname", kycDetails?.fatherFullname);
    formik.setFieldValue("motherPrefix", kycDetails?.motherPrefix);
    formik.setFieldValue("motherFname", kycDetails?.motherFname);
    formik.setFieldValue("motherFullname", kycDetails?.motherFullname);
    formik.setFieldValue("gender", kycDetails?.gender);
    formik.setFieldValue("dob", kycDetails?.dob);
    formik.setFieldValue("pan", kycDetails?.pan);
    formik.setFieldValue("permLine1", kycDetails?.permLine1);
    formik.setFieldValue("permLine2", kycDetails?.permLine2);
    formik.setFieldValue("permLine3", kycDetails?.permLine3);
    formik.setFieldValue("permCity", kycDetails?.permCity);
    formik.setFieldValue("permDist", kycDetails?.permDist);
    formik.setFieldValue("permState", kycDetails?.permState);
    formik.setFieldValue("permCountry", kycDetails?.permCountry);
    formik.setFieldValue("permPin", kycDetails?.permPin);
    formik.setFieldValue("permCorresSameflag", kycDetails?.permCorresSameflag);
    formik.setFieldValue("corresLine1", kycDetails?.corresLine1);
    formik.setFieldValue("corresLine2", kycDetails?.corresLine2);
    formik.setFieldValue("corresCity", kycDetails?.corresCity);
    formik.setFieldValue("corresState", kycDetails?.corresState);
    formik.setFieldValue("corresDist", kycDetails?.corresDist);
    formik.setFieldValue("corresCountry", kycDetails?.corresCountry);
    formik.setFieldValue("corresPin", kycDetails?.corresPin);
    formik.setFieldValue("email", kycDetails?.email);
    formik.setFieldValue("decDate", kycDetails?.decDate);
    formik.setFieldValue("decPlace", kycDetails?.decPlace);
    formik.setFieldValue("numIdentity", kycDetails?.numIdentity);
    formik.setFieldValue("numImages", kycDetails?.numImages);

  }, [kycDetails]);

  useEffect(() => {
    console.log("formContext.selectedHealthQuoteData", formContext.selectedHealthQuoteData)
    formik.setFieldValue("basePremium", formContext.selectedHealthQuoteData?.basePremium)
    formik.setFieldValue("TotalPremium", formContext.selectedHealthQuoteData?.premium)

    const riders = formContext.selectedHealthQuoteData?.riderList.filter(rider => rider.type === 'M');
    const addons = formContext.selectedHealthQuoteData?.riderList.filter(rider => rider.type === 'O');

    formik.setFieldValue("riders", riders)
    formik.setFieldValue("addons", addons)
  }, [formContext.selectedHealthQuoteData])

  useEffect(() => {
    console.log("formContext.selectedRiders", formContext.selectedRiders)
  }, [formContext.selectedRiders])

  const titleArray = [
    "Add-ons and rider selection",
    "Required information for KYC verification",
    "Contact information of the proposer",
    "Family details of the proposer",
    "Medical questionnaire",
    // "Person who will receive the benefit in case of death of proposer",
    "Nominee details",
    "Address details ",
  ];

  const pages = [
    <RiderSelection {...props} formik={formik} />,
    <KYCForm {...props} formik={formik} />,
    <ContactInfo {...props} formik={formik} kycDetails={kycDetails} />,
    <MemberDetailis {...props} formik={formik} />,
    <MedicalQuestionnaire {...props} formik={formik} />,
    <Nominee {...props} formik={formik} />, //Nominee
    <AddressDetails {...props} formik={formik} />,
    <PaymentForm {...props} formik={formik} />,
  ];

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    isSecondStep,
    back,
    next,
  } = UseMultistepForm(pages);

  useEffect(() => {
    formik.setFieldValue("currentStepIndex", currentStepIndex.toString());
  }, [currentStepIndex]);

  const careKycVerifyAPI = async (values) => {
    console.log("values", values);
    formContext.setloaderStatus(true);

    try {
      const result = await careKycVerify(values);
      console.log("result", result);

      if (result?.data?.data) {
        const kycData = result?.data?.data;
        console.log("#########KYC Data##########", kycData);
        formContext.setloaderStatus(false);
        return kycData;
      }
    } catch (error) {
      console.log("KYC Verification failed:", error);
      formContext.setloaderStatus(false);

      setErrorMessage("KYC Verification failed. Please check your details and try again.");

    }
  };
  const paymentApicalls = (data) => {
    paymentApi(data)
      .then((item) => {
        console.log("payment create", item);
        const searchCityData = JSON.parse(item?.data);
        console.log("paymentData", searchCityData?.data);
        if (!sessionStorage.getItem("isAdmin")) {
          // crmStateUpdate(data);
        }
        openpaymentWindow(searchCityData?.data, data);
      })
      .catch((err) => {
        console.log("error", err);
        // commonErrorMessage();
      })
      .finally(() => {
        formContext.setloaderStatus(false);
      });
  };

  const openpaymentWindow = (arraydata, generateproData) => {
    console.log("data33333333333333333333", arraydata);
    let urlCombined = arraydata?.paymentURL;
    localStorage.setItem("proposalId", generateproData?.proposalId)
    localStorage.setItem("lob", "health");
    console.log("urlCombined", urlCombined);

    var urlCombinedForFutureG =
      formContext?.selectedHealthQuoteData?.insuranceCompany ===
        "Care Health Insurance"
        ? "/careinsurance"
        : "/futureinsurance";

    arraydata?.paramterList?.map((mapdata, index) => {
      urlCombined =
        urlCombined +
        `${index === 0 ? "?" : "&"}` +
        mapdata.name +
        "=" +
        mapdata.value;
      urlCombinedForFutureG =
        urlCombinedForFutureG +
        `${index === 0 ? "?" : "&"}` +
        mapdata.name +
        "=" +
        mapdata.value;

      return (paymentdataDestructure[mapdata.name] = mapdata.value);
    });

    urlCombinedForFutureG =
      urlCombinedForFutureG + "&&paymentURL=" + arraydata?.paymentURL;
    let urlCombinedForhdfc =
      urlCombinedForFutureG + "&&paymentURL=" + arraydata?.paymentURL;
    console.log(
      "urlCombinedForhdfc++urlCombinedForFutureG",
      urlCombinedForhdfc,
      urlCombinedForFutureG
    );
    // code for futureindia
    if (
      formContext?.selectedHealthQuoteData?.insuranceCompany ===
      "Care Health Insurance"
    ) {
      window.open(
        urlCombinedForFutureG,
        "_self",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
      );
      //  history.push(urlCombinedForFutureG);
    } else if (
      formContext?.selectedHealthQuoteData?.insuranceCompany ===
      "Care Health Insurance"
    ) {
      consentGiven &&
        window.open(
          urlCombinedForFutureG,
          "_self",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
        );
    } else {
      window.open(
        urlCombined,
        "_self",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
      );
    }
  };

  const apiCalls = async (values) => {
    
    submitHealthProposalApi(values, formContext.selectedRiders).then((res) => {
console.log("inside apicalls ", formContext.selectedRiders)
      if (res?.data?.data?.proposalNum) {
        setProposalResponse(res.data.data)
        // paymentApicalls(res?.data?.data)
        setProposalNumber(res?.data?.data.proposalNum)
        console.log("Res-->", res?.data?.data)
        if (!isCareKYCVerified) {
          setkycPopUp(true)
        } else {
          setshowBreakupPopup(true)
        }
      }
      else{
        formContext.notifyError("Something went wrong ")
        formContext.setloaderStatus(false)
      }
    }).catch((err)=>{
       formContext.notifyError("something went wrong ")
       formContext.setloaderStatus(false)
    })
  };



  function calculateBreakupPremium() {

    if (proposalResponse) {
      console.log("proposal response",proposalResponse)
      const selectedRiderAmount = proposalResponse?.ridersList.reduce((accumulator, item) => {
        if (item.amount) {
          console.log("selected riders", proposalResponse.riderList)
          console.log("item-amount",item.amount)
          // Convert item.amount to a number to avoid NaN errors
          console.log(accumulator+Number(item.amount))
          return accumulator + Number(item.amount);
          
        }
        return accumulator;
      }, 0);

      const quoteBasePremium = Number(proposalResponse.quoteBasePremium.replace(/,/g, ""));
      console.log("quote base premium",proposalResponse.quoteBasePremium)
    //when amount was coming from response we written in this way 
      // const tax = Number(proposalResponse?.tax);
      //when tax is not coming from response then we consider 0
      const tax = 0
      const totalAmount = quoteBasePremium + tax + selectedRiderAmount;
      console.log("check ------>", proposalResponse?.selectedRiderAmount);
      console.log("total amount ", totalAmount);
      const defaultRiderAmount = (proposalResponse?.premium - totalAmount).toFixed(2)
      console.log("default amount",defaultRiderAmount)
      console.log("default Rider Amount ", defaultRiderAmount);
      return defaultRiderAmount;
    }
  }

  return (
    <div className="forms-wrap">
      <div className="form-title">
        <p className="form-sub-title">{titleArray[currentStepIndex]}</p>
        <p className="page-nuber">
          ({currentStepIndex + 1} / {steps.length})
        </p>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        {step}
        {validationError && (
            <div >
              <strong style={{ color: 'red', }}>Please fill in all the required fields properly.</strong>
            </div>
          )}
        <div
          style={{
            display: "flex",
            gap: ".5rem",
            justifyContent: "flex-end",
            right: "10px",
            bottom: "20px",
          }}
          className="btn-spinner"
        >
          {!isFirstStep && (
            <Button
              type="button"
              onClick={async () => {
                await formik.validateForm(formik.values);
                back();
              }}
              className=" back-btn"
              disabled={formContext.loaderStatus}
            >
              Back
            </Button>
          )}

          <Button
            type="submit"
            className="primary-btn"
            onClick={async () => {
              await formik.validateForm(formik.values);
              if (Object.keys(formik.errors).length === 0) {
                setValidationError(false);
                handleNextSubmit(formik.values);
              } else {
                setValidationError(true);
                handleNextSubmit(formik.values);
              }
            }}
            disabled={formContext.loaderStatus}
          >
            {isLastStep ? "Finish" : "Next"}
            {formContext.loaderStatus && formContext.spinner}
          </Button>
        </div>
        {/* Error Modal */}
        <VerticallyCenteredModal
          show={errorModalShow}
          onHide={() => setErrorModalShow(false)}
          heading="Error"
        >
          <p>{errorMessage}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              className=" back-btn"
              onClick={() => setErrorModalShow(false)}
            >
              Cancel
            </Button>
            {/* You can add any logic or handle actions needed for the "OK" button */}
            <Button
              className=" primary-btn"
              onClick={() => setErrorModalShow(false)}
            >
              OK
            </Button>
          </div>
        </VerticallyCenteredModal>
        {/* pop-up for OVD */}
        <VerticallyCenteredModal
          show={kycPopUp}
          onHide={() => {
            setkycPopUp(false)
          }}
          heading="KYC not found"
          hideCloseIcon={true}
        >
          <div className="kyc-doc-wrap">
            <p className="text-center mt-0">
              Please Upload identity and address proof
            </p>
            <div className="upload-section">
              <div className="document-container">
                <div className="form-floating mb-4">
                  <select
                    id="iduniqu"
                    className="form-select"
                    onChange={(e) => docTypeChanges(e.target.value, 1)}
                  >
                    {poiType?.map((data) => {
                      return <option value={data?.value}>{data?.label}</option>;
                    })}
                  </select>
                  <label htmlFor="iduniqu select-label">
                    identity Document
                  </label>
                </div>
                <FileUpload returnData={returnData} type={1} />
              </div>
              <div className="document-container">
                <div className="form-floating mb-4">
                  <select
                    id="idunique"
                    className="form-select"
                    onChange={(e) => docTypeChanges(e.target.value, 2)}
                  >
                    {poAType?.map((data) => {
                      return <option value={data?.value}>{data?.label}</option>;
                    })}
                  </select>
                  <label htmlFor="idunique select-label">
                    Address Document
                  </label>
                </div>
                <FileUpload returnData={returnData} type={2} />
              </div>
            </div>
            {carekycError && (
              <p className="text-center" style={{ color: "red", fontSize: 16 }}>
                All four fileds are required{" "}
              </p>
            )}
            <p className="text-center mb-0">
              <span className="font-weight-bold">Note:</span> Fornt and back
              side must be uploaded as a single image for voter ID, Passport,
              AADHAAR{" "}
            </p>
            <p className="text-center mb-0">
              Expected format are: pdf, jpg, jpeg, png and max size up t0 10MB.{" "}
            </p>
            <div className="text-center mt-3">
              <Button className="cancel-btn" onClick={() => setkycPopUp(false)}>
                Cancel
              </Button>
              <Button className="primary-btn" onClick={() => careDocKycSubmit()}>
                Submit
              </Button>
            </div>
          </div>
        </VerticallyCenteredModal>

      </form>
      <>
        <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />

        {/* Iframe value popup */}
        <VerticallyCenteredModal
          show={iframeStatus}
          onHide={() => {
            // onIframeHide()
          }}
          heading="This step is mandatory to download the policy."
        >
          <div>
            <iframe
              src={iframeUrl}
              title="Please fill all data"
              width={1000}
              height={700}
            ></iframe>
          </div>
        </VerticallyCenteredModal>
        {/* for popup kyc */}
        <VerticallyCenteredModal
          show={kycPopUpStatus}
          onHide={() => {
            // onIframeHide()
          }}
          heading="This step is mandatory to download the policy."
        >
          <div className="kyc-failed-popup">
            <div className="text-center mt-2">
              <BiSolidError className="failed-icon" />
              <p className="mt-2" style={{ color: "red" }}>
                {errorMessage}
              </p>
              <div className="text-center mt-3 mb-3 text-info-label">
                Click on below Button for KYC verification
              </div>
              <div className=" footer-btn-wrap">
                <Button className="primary-btn">Proceed for KYC </Button>
              </div>
            </div>
          </div>
        </VerticallyCenteredModal>

      </>
      <Container>


        {/* Modal for Premium Breakup */}
        <Modal show={showBreakupPopup} onHide={() => {
          setshowBreakupPopup(false);
          formContext.setloaderStatus(false);
        }} centered>
          <Modal.Header closeButton>
            <Modal.Title>Premium Break-up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Amount (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Base Premium</td>
                      <td>{proposalResponse?.quoteBasePremium ?? ""}</td>
                    </tr>
                    <tr>
                      <td>
                        Unlimited Automatic Recharge, Pre and Post Hospitalization, Unlimited E-Consultation
                      </td>
                      <td>{calculateBreakupPremium()}</td>
                    </tr>
                    {proposalResponse?.ridersList.map((rider, index) => {
                      if (rider.amount) {
                        return <tr key={index}>
                          <td>{rider.name}</td>
                          <td>{rider.amount}</td>
                        </tr>
                      }
                      return '';
                    })}
                    {/* <tr>
                    <td>Subtotal (Base Premium + Riders)</td>
                    <td>{basePremium + totalRiderAmount}</td>
                  </tr> */}
                    <tr>
                      <td>Tax ({taxRate}%)</td>
                      {/* working  */}
                      {/* <td>{proposalResponse?.tax}</td> */}
                      <td>NA</td>
              
                    </tr>
                    <tr>
                      <td>Total Premium</td>
                      <td>{proposalResponse?.premium ?? ""}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setshowBreakupPopup(false);
              formContext.setloaderStatus(false);
            }}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                paymentApicalls(proposalResponse);
                setshowBreakupPopup(false); 
              }}
            >
              Proceed to Payment
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="success-modal"
    >
      <div className="check-icon-wrap">
        <SVG src={Done} alt="" style={{ width: "80px" }} />
      </div>
      <h3 className="text-center mt-3">Done</h3>
      <Modal.Body className="p-0">
        <p className="text-center mt-0">register successfully!</p>
      </Modal.Body>
      <Modal.Footer className="modalfooter">
        <Button className="primary-btn" onClick={props.onHide}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default MultistepProposal;