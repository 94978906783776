import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
  Card,
} from "react-bootstrap";
import ContainerForForm from "../MultipleForms/CountainerForForm";
import UseFormContext from "../../../src/context/UseFormContext";
import SVG from "react-inlinesvg";
import { useHistory, useParams } from "react-router-dom";
import Arrow from "../../images/down-arrow.svg";
import VerticallyCenteredModal from "../commonModules/Popups/VerticallyCenteredModal";
import reliance from "../../images/reliance.png";
import futureGenrali from "../../images/futureGenrali.png";
import ICICI from "../../images/ICICI-insurance.png";
import Bajaj from "../../images/bajaj.jpeg";
import Godigit from "../../images/Godigit.png";
import NewIndia from "../../images/new-india.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye } from "react-icons/fa";
import HDFC from "../../images/hdfc-ergo-gi.png";
// import { Document, Page } from 'react-to-pdf';
import generatePDF from "react-to-pdf";
import {
  convertDate,
  initialDataQuotesPage,
  bikeFormikIntialData,
  expDateValidation,
} from "../commonModules/CommonCode";
import * as api from "../../API/authCurd";
import { DateConvertFunction } from "../commonModules/CommonCode";
export default function ProposalPage() {
  const { id } = useParams();
  const { proposalID } = useParams();

  const formContext = UseFormContext();
  console.log("formcontext", formContext);
  const [quotePremiumPopup, setQuotePremiumPopup] = useState(false);
  const [netPremiumForBrekupvalue, setnetPremiumForBrekupvalue] = useState();
  let statDate = formContext?.PolicyDates?.policyDate?.policyStartDate;
  let iciciStartDate = new Date(formContext?.singleQuotesData?.PolicyStartDate);
  const iciciStartDateFinal = iciciStartDate.toLocaleDateString("en-GB");
  console.log("iciciStartDateFinal", iciciStartDateFinal);
  statDate = statDate?.split("/");
  let value = statDate?.[2] + "-" + statDate?.[1] + "-" + statDate?.[0];
  let history = useHistory();
  const ref = useRef();
  const [policyStartDate, setpolicyStartDate] = useState();
  const valuesForExpDate = expDateValidation();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // en-GB format is dd/mm/yyyy
  };

  useEffect(() => {
    if (proposalID && proposalID !== null) {
      api
        .getProposal(proposalID)
        .then((data) => {
          if (data.data) {
            const jsonData = JSON.parse(data?.data);
            formContext.setsingleQuotesData(
              jsonData?.data?.proposalRequest?.premiumDetails
            );
            formContext.setproposalData(jsonData?.data);
            history.push("/proposal/2");
          }
        })
        .catch((err) => {
          formContext.notifyError("Facing some error ,please try again.");
        });
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (id === "2") {
      const quotesID = formContext?.proposalData?.QUOTE_ID;
      if (quotesID) {
        api
          .shareQuotes(quotesID)
          .then((data) => {
            const jsonData = JSON.parse(data.data);
            const businessLineId = jsonData?.data.businessLineId;
            var nestedDataVeh;
            var nestedData;
            var nestedDate;
            if (businessLineId === 2) {
              formContext.setroutingPath("/bike/1");
              nestedData = jsonData?.data?.bikeQuoteRequest?.quoteParam;
              localStorage.setItem("policyType", nestedData?.planType);
              nestedDataVeh = jsonData?.data?.bikeQuoteRequest?.vehicleInfo;
              nestedDate = jsonData?.data?.bikeQuoteRequest;
            } else {
              formContext.setroutingPath("/car/1");
              nestedData = jsonData?.data?.carQuoteRequest?.quoteParam;
              nestedDataVeh = jsonData?.data?.carQuoteRequest?.vehicleInfo;
              nestedDate = jsonData?.data?.carQuoteRequest;
            }
            var policyTypeArray = nestedData?.planType?.split("-");
            var policyType;
            var policyTerm;

            if (policyTypeArray?.length === 3) {
              policyType = policyTypeArray[0] + "-" + policyTypeArray[1];
              policyTerm = policyTypeArray[2];
            } else {
              policyType = policyTypeArray[0];
              policyTerm = policyTypeArray[1];
              if (businessLineId === 3 && policyTypeArray?.length === 2) {
                policyType = policyTypeArray[0] + "-" + policyTypeArray[1];
              } else if (
                businessLineId === 3 &&
                policyTypeArray?.length === 1
              ) {
                policyType = policyTypeArray[0];
              }
            }

            const objForCarBike = {
              noClaimBonus: nestedData?.ncb,
              insuranceFor: nestedData?.policyType,
              addOnCover: nestedData?.riders,
              lastName: nestedData?.lastName,
              insuranceClaim: nestedDataVeh?.previousClaim,
              previousPolicy:
                nestedData?.policyType === "new"
                  ? valuesForExpDate.todayConvertedValue
                  : nestedDataVeh?.PreviousPolicyExpiryDate,
              firstName: nestedData?.firstName,
              email: nestedData?.email,
              phoneNumber: nestedData?.phoneNumber,
              currentStepIndex: "1",
              policyType: policyType,
              policyTerms: policyTerm,
              make: nestedDataVeh.make,
              model: nestedDataVeh.model,
              varient: nestedDataVeh.variant,
              registeredRto: nestedDataVeh?.registrationPlace
                ? nestedDataVeh?.registrationPlace
                : nestedDataVeh?.RTOCode,
              registrationYear: convertDate(
                nestedDataVeh?.dateOfRegistration,
                2
              ),
              formtype: jsonData?.data.businessLineId === 2 ? "bike" : "car",
              fuel: nestedDataVeh.fuel
                ? nestedDataVeh.fuel
                : nestedDataVeh.fuelType
                ? nestedDataVeh.fuelType
                : "petrol",
            };

            const objForQuotesPage = {
              noClaimBonus: nestedData?.ncb,
              insuranceFor: nestedData?.policyType,
              addOnCover: nestedData?.riders,
              insuranceClaim: nestedDataVeh?.previousClaim,
              policyType: policyType,
              policyTerms: policyTerm,
              make: nestedDataVeh.make,
              model: nestedDataVeh.model,
              varient: nestedDataVeh.variant,
              registeredRto: nestedDataVeh.RTOCode,
              registrationYear: convertDate(
                nestedDataVeh?.dateOfRegistration,
                2
              ),
              expiryDate: convertDate(
                nestedDataVeh?.PreviousPolicyExpiryDate,
                2
              ),
              fuel: nestedDataVeh.fuel
                ? nestedDataVeh.fuel
                : nestedDataVeh.fuelType
                ? nestedDataVeh.fuelType
                : "petrol",
              idv: nestedDataVeh?.IDV,
              ownedBy:
                nestedData?.ownedBy === "Individual"
                  ? "Individual"
                  : "Organization",
              zeroDep: "",
              personalCover: 50000,
              personalCoverFlag: "",
              personalAccidentCover: false,
              driverAccidentCover: "",
              driverAccidentCoverAmount: 10000,
              lpgCngKit: "",
              lpgCngKitAmount: "",
              accessories: "",
              electricalAccessories: "",
              electricalAccessoriesAmount: "",
              nonElectricalAccessories: "",
              nonElectricalAccessoriesAmount: "",
              roadSideAssistance: "",
              ncbProtection: "",
              engineProtector: "",
              tyreProtection: "",
              tyreDetails: "",
              tyreProtectionAmount: "",
              keyProtection: "",
              consumablesCover: "",
              baggageCover: "",
              invoiceCover: "",
              transportHotelExpenses: "",
              transportHotelExpensesAmount: "",
              tpPolicyStartDate: nestedDataVeh?.TPPolicyStartDate
                ? nestedDataVeh.TPPolicyStartDate === "undefined-undefined-"
                  ? null
                  : nestedDataVeh?.TPPolicyStartDate
                : null,
              tpPolicyEndtDate: nestedDataVeh?.TPPolicyExpiryDate
                ? nestedDataVeh?.TPPolicyExpiryDate === "undefined-undefined-"
                  ? null
                  : nestedDataVeh?.TPPolicyExpiryDate
                : null,
              isvehNumberMissing: true,
              idvSelectedValue: nestedDataVeh?.IDV,
              formtype: jsonData?.data.businessLineId === 2 ? "bike" : "car",
            };

            const valuesForCarBike = {
              ...bikeFormikIntialData,
              ...objForCarBike,
            };
            const valuesForQuotes = {
              ...initialDataQuotesPage,
              ...objForQuotesPage,
            };
            formContext.setquotesPageFormikData(valuesForQuotes);
            formContext.setcarbikeformikValues(valuesForCarBike);

            // saving dates and date which are required while creating quotes and on propsal page respectively
            const policydates = {
              QUOTE_ID: jsonData?.data?.QUOTE_ID,
              policyDate: {
                policyStartDate:
                  nestedDate?.systemPolicyStartDate?.sysPolicyStartDate,
              },
            };
            formContext.setPolicyDates(policydates);
          })
          .catch((err) => {})
          .finally((fin) => {
            formContext.setloaderStatus(false);
          });
      }
    }
  }, []);

  const polType = (policyType) => {
    if (formContext.carbikeformikValues?.formtype === "bike") {
      if (policyType === "OD-TP") {
        return "Two Wheeler Comprehensive Insurance";
      } else if (policyType === "OD") {
        // Add more conditions or a default value here if needed
        return "Two Wheeler Standalone OD Insurance";
      } else if (policyType === "TP") {
        // Add more conditions or a default value here if needed
        return "Two Wheeler Standalone TP Insurance";
      }
    } else {
      if (policyType === "OD-TP") {
        return "Four Wheeler Comprehensive Insurance";
      } else if (policyType === "OD") {
        // Add more conditions or a default value here if needed
        return "Four Wheeler Standalone OD Insurance";
      } else if (policyType === "TP") {
        // Add more conditions or a default value here if needed
        return "Four Wheeler Standalone TP Insurance";
      }
    }
  };

  let policyType = polType(localStorage.getItem("policyType"));

  let policyTerms = localStorage.getItem("policyTerms");

  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": reliance,
    "New India Assurance": NewIndia,
    "Future Generali India Insurance Co. Ltd": futureGenrali,
    "GO DIGIT General Insurance CO. LTD": Godigit,
    "ICICI Lombard General Insurance Co. Ltd": ICICI,
    "HDFC ERGO General Insurance Company": HDFC,
    "Bajaj Allianz General Insurance": Bajaj,
  };
  const backButtonPressed = () => {
    formContext.setgetquotesApiFlag(false);
    history.push("/quotes/1");
  };
  const QpPopupClose = () => {
    setQuotePremiumPopup(false);
  };
  const openQuotePopup = (data) => {
    var premium = 0;
    var odpremium = 0;
    // for new india we get array for net premium and for idv so we need this logic
    if (typeof data?.netPremium === "object") {
      data?.netPremium?.forEach((foreachdata, index) => {
        premium = premium + foreachdata;
      });
    } else {
      premium = data?.netPremium;
    }

    if (typeof data?.odpremium === "object") {
      data?.odpremium?.forEach((foreachdata, index) => {
        odpremium = odpremium + foreachdata;
      });
    } else {
      odpremium = data?.odpremium;
    }
    setnetPremiumForBrekupvalue({ premium: premium, odpremium: odpremium });
    formContext.setsingleQuotesData(data);
    setQuotePremiumPopup(!quotePremiumPopup);
  };

  const expiryDate = formContext?.quotesPageFormikData?.expiryDate
    ?.split("-")
    ?.reverse()
    ?.join("/");
  console.log("single quote data", formContext.carbikeformikValues);

  const currentDate = DateConvertFunction(new Date(), 13);
  const tenuarDate = DateConvertFunction(
    new Date(),
    15,
    Number(policyTerms)?.toFixed(0)
  );

  const quotePremium = (
    <div>
      <div className="premium-container" ref={ref}>
        <div className="topSection">
          <div className="img-wrap">
            <img
              src={
                imgArrayForCard?.[
                  formContext.singleQuotesData?.insuranceCompany
                ]
              }
              alt=""
            />
          </div>
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Idv</span>
              <h3>₹ {formContext.singleQuotesData?.insuredDeclareValue}</h3>
            </div>
          </div>
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Premium</span>
              <h3>₹ {Number(netPremiumForBrekupvalue?.premium)?.toFixed(0)}</h3>
            </div>
            <span className="help-text">Exclusive of GST</span>
          </div>
        </div>
        <p>{formContext.singleQuotesData?.insuranceCompany}</p>
        <div className="tab-section">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Text>
                    <span className="help-text">Name : </span>
                    <span>
                      {formContext.carbikeformikValues?.firstName +
                        " " +
                        formContext.carbikeformikValues?.lastName}
                    </span>
                  </Card.Text>
                </Col>

                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Registration Number : </span>
                    <span>
                      {formContext.carbikeformikValues?.registeredRto}
                    </span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Plan Type: </span>
                    <span>
                      {formContext.quotesPageFormikData?.policyType === "OD-TP"
                        ? "Comprehensive"
                        : formContext.quotesPageFormikData?.policyType === "OD"
                        ? "Own Damage only"
                        : formContext.quotesPageFormikData?.policyType === "TP"
                        ? "Third Party Only"
                        : ""}
                    </span>
                  </Card.Text>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Make : </span>
                    <span>{formContext.carbikeformikValues?.make}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Model : </span>
                    <span>{formContext.carbikeformikValues?.model}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Varient : </span>
                    <span>{formContext.carbikeformikValues?.varient}</span>
                  </Card.Text>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Fuel : </span>
                    <span>{formContext.carbikeformikValues?.fuel}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">
                      <b>Policy Start Date : </b>
                    </span>
                    <span type="date" style={{ fontSize: "13px" }}>
                      {formContext?.PolicyDates?.policyDate?.policyStartDate}
                    </span>
                  </Card.Text>
                </Col>

                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">
                      <b>Policy End Date : </b>
                    </span>
                    <span type="date" style={{ fontSize: "13px" }}>
                      {formContext?.PolicyDates?.policyDate?.policyEndDate}
                    </span>
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="tabs">
            <span className="tab-text">Premium Details</span>
          </div>
          <div className="tab-content">
            {netPremiumForBrekupvalue?.odpremium !== 0 && (
              <div className="tab-row">
                <p>OD premium</p>
                {typeof netPremiumForBrekupvalue?.odpremium === "number" && (
                  <p>
                    ₹ {Number(netPremiumForBrekupvalue?.odpremium)?.toFixed(0)}
                  </p>
                )}
                {typeof netPremiumForBrekupvalue?.odpremium === "string" && (
                  <p>
                    ₹{" "}
                    {Number(
                      netPremiumForBrekupvalue?.odpremium.replace("INR", "")
                    )?.toFixed(0)}
                  </p>
                )}
              </div>
            )}
            {formContext.singleQuotesData?.insuranceCompany ===
            "New India Assurance"
              ? formContext.singleQuotesData?.tppremium !== 0 && (
                  <div className="tab-row">
                    <p>TP premium</p>
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "number" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium
                        )?.toFixed(0) * Number(policyTerms)}
                      </p>
                    )}
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "string" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium.replace(
                            "INR",
                            ""
                          )
                        )?.toFixed(0) * Number(policyTerms)?.toFixed(0)}
                      </p>
                    )}
                  </div>
                )
              : formContext.singleQuotesData?.tppremium !== 0 && (
                  <div className="tab-row">
                    <p>TP premium</p>
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "number" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium
                        )?.toFixed(0)}
                      </p>
                    )}
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "string" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium.replace(
                            "INR",
                            ""
                          )
                        )?.toFixed(0)}
                      </p>
                    )}
                  </div>
                )}

            {formContext.singleQuotesData?.ridersList?.map((mapdata) => {
              // Check if riderValue is non-zero and valid
              if (
                mapdata?.riderValue &&
                mapdata?.riderValue !== 0 &&
                mapdata?.riderValue !== "0" &&
                mapdata?.riderValue !== null &&
                mapdata?.riderValue !== undefined
              ) {
                return (
                  <div className="tab-row" key={mapdata.riderId}>
                    <p>{mapdata?.riderName}</p>
                    {mapdata?.riderValue?.toString().includes("INR") ? (
                      <p>
                        ₹{" "}
                        {Number(mapdata?.riderValue.replace("INR", "")).toFixed(
                          0
                        )}
                      </p>
                    ) : (
                      <p>₹ {Number(mapdata?.riderValue).toFixed(0)}</p>
                    )}
                  </div>
                );
              }
              return null; // Return null if riderValue is 0
            })}

            {formContext.carbikeformikValues?.formtype === "bike"
              ? formContext.singleQuotesData?.discountList?.map(
                  (mapdataNcb) => {
                    const isDiscount = mapdataNcb?.type?.includes("Discount");
                    const sign = isDiscount ? "-" : "";

                    // If the type is undefined, skip rendering
                    if (!mapdataNcb?.type) {
                      return null;
                    } else if (!mapdataNcb?.discountAmount) {
                      return null;
                    } else {
                      // If the conditions for skipping the discount are met, skip rendering
                      if (
                        formContext.singleQuotesData?.insuranceCompany ===
                          "New India Assurance" &&
                        formContext.singleQuotesData?.policyType !== "new" &&
                        mapdataNcb?.type === "OD Discount" &&
                        policyTerms !== "1" &&
                        (policyTerms === "2" || policyTerms === "3")
                      ) {
                        let data = mapdataNcb?.discountAmount?.reduce(
                          (acc, curr) => acc + curr,
                          0
                        );
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹{data?.toFixed(0)}
                            </p>
                          </div>
                        );
                      } else {
                        // Render the discount item
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      }
                    }
                  }
                )
              : formContext.singleQuotesData?.discountList?.map(
                  (mapdataNcb) => {
                    const isDiscount = mapdataNcb?.type?.includes("Discount");
                    const sign = isDiscount ? "-" : "";

                    // If the type is undefined, skip rendering
                    if (!mapdataNcb?.type) {
                      return null;
                    } else if (!mapdataNcb?.discountAmount) {
                      return null;
                    } else {
                      // If the conditions for skipping the discount are met, skip rendering
                      if (
                        (formContext.singleQuotesData?.insuranceCompany ===
                          "Reliance General Insurance Co. Ltd" ||
                          formContext.singleQuotesData?.insuranceCompany ===
                            "New India Assurance" ||
                          formContext.singleQuotesData?.insuranceCompany ===
                            "Future Generali India Insurance Co. Ltd") &&
                        mapdataNcb?.type === "OD Discount"
                      ) {
                        return null;
                      } else {
                        // Render the discount item
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      }
                    }
                  }
                )}

            <div className="tab-row borderTop ">
              <p className="font-weight-bold">Net premium</p>
              <p className="font-weight-bold">
                ₹ {Number(netPremiumForBrekupvalue?.premium)?.toFixed(0)}
              </p>
            </div>

            <div className="tab-row">
              <p>GST 18% </p>
              <p>
                ₹ {Number(formContext.singleQuotesData?.serviceTax)?.toFixed(0)}
              </p>
            </div>
            <div className="tab-row borderTop total-wrap">
              <p>Total Premium</p>
              <p>
                ₹{" "}
                {Number(formContext.singleQuotesData?.grossPremium)?.toFixed(0)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-3">
        <button
          className="primary-btn"
          onClick={() =>
            generatePDF(ref, { filename: "PremiumBreakpoints.pdf" })
          }
        >
          Download PDF
        </button>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="proposal-wrap">
        <Container fluid className="form-bg">
          <Row>
            <Col sm={12} md={4} className="p-left">
              <div className="leftside-form">
                <h3 className="title">
                  Premium Amount ₹{" "}
                  {Number(formContext.singleQuotesData?.grossPremium)?.toFixed(
                    0
                  )}
                  <p className="premium-text mb-0">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-tex">
                          Click to view breakup
                        </Tooltip>
                      }
                    >
                      <span
                        onClick={() => {
                          openQuotePopup(formContext.singleQuotesData);
                        }}
                      >
                        <FaEye />
                      </span>
                    </OverlayTrigger>
                  </p>
                </h3>
                <p>(All prices are inclusive of GST)</p>

                <div>
                  <div className="sub-title">
                    <p className="sub-title_text">Coverage Details</p>
                  </div>
                  <div>
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Insured declare value(IDV)"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Insured declare value(IDV)"
                        className="floating-input"
                        value={
                          formContext?.singleQuotesData?.insuredDeclareValue
                        }
                        disabled
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Product"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Product"
                        className="floating-input"
                        value={
                          formContext?.proposalData?.proposalRequest
                            ?.premiumDetails?.productType || policyType
                        }
                        disabled
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Policy start date"
                      className="mb-3"
                    >
                      <Form.Control
                        type={
                          formContext.singleQuotesData?.insuranceCompany ===
                          "ICICI Lombard General Insurance Co. Ltd"
                            ? "text"
                            : "date"
                        }
                        placeholder="Product"
                        className="floating-input"
                        disabled
                        value={
                          formContext.singleQuotesData?.insuranceCompany ===
                          "ICICI Lombard General Insurance Co. Ltd"
                            ? iciciStartDateFinal
                            : policyStartDate
                            ? policyStartDate
                            : value
                        }
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <p className="sub-title_text">Vehicle Details</p>
                  </div>
                  <div>
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Make and model"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Make and model"
                        className="floating-input"
                        value={
                          formContext?.singleQuotesData
                            ?.carrierVariantDisplayName
                        }
                        disabled
                      />
                    </FloatingLabel>
                  </div>
                  {!id && (
                    <button
                      title="Back"
                      onClick={() => backButtonPressed()}
                      className="back-button"
                    >
                      {" "}
                      <SVG src={Arrow} alt="" className="back-arrow" />
                    </button>
                  )}
                </div>
              </div>
            </Col>
            <Col sm={12} md={8} className="">
              <ContainerForForm
                vahanData={formContext.vahanData}
                singleQuotesData={formContext.singleQuotesData}
                carbikeformikValues={formContext.carbikeformikValues}
                createquotesresult={formContext.createquotesresult}
                quotesPageFormikData={formContext.quotesPageFormikData}
                PolicyDates={formContext.PolicyDates}
                carrierType={formContext.singleQuotesData?.insuranceCompany}
                id={id}
              />
            </Col>
          </Row>
        </Container>
        {/* View breakup */}
        <VerticallyCenteredModal
          show={quotePremiumPopup}
          onHide={() => {
            QpPopupClose();
          }}
        >
          {quotePremium}
        </VerticallyCenteredModal>
      </div>
    </React.Fragment>
  );
}
